import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import enviroment from "./modules/enviroment";
import login from "./modules/login";

export default new Vuex.Store({
  modules: {
    enviroment,
    login,
  },
});
