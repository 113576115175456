import Chart from 'chart.js'
import reportsService from "@/services/reports/reports.service";

export default {
    name: 'BarChart',
    props: {
        header: Array,
        filter: Object,
        allData: Boolean,
        totalBalanceXAxis: Object
    },
    data() {
        return {
            statisticData: {
                AccountNr: 0,
                AccountName: 0,
                WasteAmountKg: 0,
                WasteAmountPercent: 0,
                CostsFr: 0,
                CostsPercent: 0,
                CostsFrT: 0,
                RevenuesFr: 0,
                RevenuesPercent: 0,
                RevenuesFrT: 0,
                CostRecoveryFr: 0
            },
            graph: {},
            BarChart: {
                type: "bar",
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    responsive: true,
                    lineTension: 1,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    padding: 25
                                }
                            }
                        ]
                    }
                }
            },
            WasteAmountDataPlaceholer: {
                label: "Abfallmenge",
                type: "bar",
                data: [],
                backgroundColor: "#f5f9e9",
                borderColor: "#f5f9e9",
                borderWidth: 3
            },
            CostsDataPlaceholder: {
                label: "Kosten",
                type: "bar",
                data: [],
                backgroundColor: "#dc3545",
                borderColor: "#dc3545",
                borderWidth: 3
            },
            RevenuesDataPlaceholder: {
                label: "Erlöse",
                type: "bar",
                data: [],
                backgroundColor: "#a2c309",
                borderColor: "#a2c309",
                borderWidth: 3
            }
        }
    },
    async created() {
        this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        this.createChart();
    },
    mounted() {
        this.$root.$on('update_statistics',async () => {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
            }
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.accounts': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.startDate': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.endDate': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.WasteAmount': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.Costs': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.Revenues': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
    },
    methods: {
        getData() {
            this.BarChart.data.labels = [];
            this.BarChart.data.datasets = [];
            this.WasteAmountDataPlaceholer.data = [];
            this.CostsDataPlaceholder.data = [];
            this.RevenuesDataPlaceholder.data = [];
            for (let i = 0; i < this.statisticData.length; i++) {
                this.BarChart.data.labels.push(this.statisticData[i].AccountName);
                this.WasteAmountDataPlaceholer.data.push(this.statisticData[i].WasteAmountKg);
                this.CostsDataPlaceholder.data.push(this.statisticData[i].CostsFr);
                this.RevenuesDataPlaceholder.data.push(this.statisticData[i].RevenuesFr);
            }
            if (this.totalBalanceXAxis.WasteAmount) {
                this.BarChart.data.datasets.push(this.WasteAmountDataPlaceholer);
            }
            if (this.totalBalanceXAxis.Costs) {
                this.BarChart.data.datasets.push(this.CostsDataPlaceholder);
            }
            if (this.totalBalanceXAxis.Revenues) {
                this.BarChart.data.datasets.push(this.RevenuesDataPlaceholder);
            }
        },
        createChart() {
            this.getData();
            const ctx = document.querySelector('.bar-chart');
            this.graph = new Chart(ctx, this.BarChart);
        },
        updateChart() {
            this.getData();
            this.graph.update(this.BarChart);
        }
    }
}