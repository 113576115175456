import CreateBill from "@/components/CreatePopup/Sections/CreateBill/CreateBill.vue";
import CreateAddress from "@/components/CreatePopup/Sections/CreateAddress/CreateAddress.vue";
import CreatePassword from "@/components/CreatePopup/Sections/CreatePassword/CreatePassword.vue";
import CreateOrganisation from "@/components/CreatePopup/Sections/CreateOrganisation/CreateOrganisation.vue";
import CreateDepartment from "@/components/CreatePopup/Sections/CreateDepartment/CreateDepartment.vue";
export default {
  name: "UpdatePopup",
  props: {
    bill: Object,
    contact: Object,
    organisation: Object,
    department: Object
  },
  components: {
    CreateBill,
    CreateAddress,
    CreatePassword,
    CreateOrganisation,
    CreateDepartment
  },
  data() {
    return {
      createPopupIsOpen: false,
      showPopup: false,
      enviroment: {},
    };
  },
  methods: {
    close() {
      this.$emit("close_update_popup", true);
    },
  },
};
