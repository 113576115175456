// @ is an alias to /src
import { mapGetters, mapMutations } from "vuex";
import yearsService from "@/services/years/years.service";
import communesService from "@/services/communes/communes.service";

export default {
  name: "StateChangerPopup",
  components: {},
  data() {
    return {
      selectedYear: 0,
      selectedCommune: {},
      communes: {},
      years: {},
      enviroment: {},
    };
  },
  async created() {
    this.years = await yearsService.getYears();
    this.communes = await communesService.getComunes();
    this.enviroment = this.getEnviroment();
    this.selectedYear = this.enviroment.currentYear;
    this.selectedCommune = this.enviroment.currentCommunity;
  },
  watch: {
    selectedYear: function (newYear) {
      this.SET_YEAR(newYear);
      this.$emit("update_state", "");
      this.$root.$emit("update_overview");
      this.$root.$emit("update_statistics");
    },
  },
  methods: {
    ...mapGetters(["getEnviroment"]),
    ...mapMutations(["SET_COMMUNITY", "SET_YEAR"]),
    changeCommunity(com) {
      this.SET_COMMUNITY(com);
      this.$emit("update_state", "");
      this.$root.$emit("update_overview");
      this.$root.$emit("update_statistics");
    },
  },
};
