import yearsService from "@/services/years/years.service";

export default {
  name: "CreateYear",
  components: {},
  data() {
    return {
      currentYear: {
        yearId: 0,
        year: null,
        mwst: null,
      },
      isComplete: true,
      updateMode: false,
      years: {},
    };
  },
  async created() {
    this.years = await yearsService.getYears();
  },
  methods: {
    async submit() {
      if (!this.currentYear.year || !this.currentYear.mwst) {
        this.isComplete = false;
        return;
      }
      this.isComplete = true;
      await yearsService.createYear(this.currentYear);
      this.years = await yearsService.getYears();
      this.currentYear.year = null;
      this.currentYear.mwst = null;
      this.$root.$emit("refresh_entry");
    },

    async update(shouldUpdate) {
      if (shouldUpdate) {
        if (!this.currentYear.year || !this.currentYear.mwst) {
          this.isComplete = false;
          return;
        }
        this.years = await yearsService.updateYear(this.currentYear);
        this.years = await yearsService.getYears();
      }
      this.currentYear.year = null;
      this.currentYear.mwst = null;
      this.updateMode = false;
      this.$root.$emit("refresh_entry");
    },

    async deleteYear(yearId) {
      await yearsService.deleteYear(yearId);
      this.years = await yearsService.getYears();
      this.$root.$emit("delete_entry");
    },

    async openEdit(year) {
      let yearToEdit = await yearsService.getYear(year);
      this.currentYear.year = yearToEdit[0].Year;
      this.currentYear.mwst = yearToEdit[0].MwSt;
      this.currentYear.yearId = yearToEdit[0].YearId;
      this.updateMode = true;
    },
  },
};
