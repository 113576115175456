import reportsService from "@/services/reports/reports.service";

export default {
    name: "PerAccount",
    props: {
        filter: Object,
        showTotals: true,
        timeComparisonXAxis: Object,
        allData: true,
        showData: true
    },
    data() {
        return {
            statisticData: [],
            headers: [],
            statisticTotals: []
        }
    },
    async created() {
        this.showData = true;
        const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
        this.getFilteredData(data);
    },
    mounted() {
        this.$root.$on('update_statistics', async () => {
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            this.emptyData();
            this.getFilteredData(data);
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
            }
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            this.emptyData();
            this.getFilteredData(data);
        },
        'timeComparisonXAxis.Dropdown': async function () {
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            this.emptyData();
            this.getFilteredData(data);
        },
        'timeComparisonXAxis.TotalXAxis': async function () {
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            this.emptyData();
            this.getFilteredData(data);
        },
        'timeComparisonXAxis.PerMonthXAxis': async function (value) {
            if (value) {
                this.timeComparisonXAxis.PerYearXAxis = false;
                const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
                this.emptyData();
                this.getFilteredData(data);
            }
        },
        'timeComparisonXAxis.PerYearXAxis': async function (value) {
            if (value) {
                this.timeComparisonXAxis.PerMonthXAxis = false;
                const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
                this.emptyData();
                this.getFilteredData(data);
            }
        },
        'filter.endDate': async function () {
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            this.emptyData();
            this.getFilteredData(data);

        },
        'filter.accounts': async function () {
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            this.emptyData();
            this.getFilteredData(data);
        },
    },
    methods: {
        emptyData() {
            this.headers.length = 0;
            this.statisticData.length = 0;
            this.statisticTotals.length = 0;
        },
        getFilteredData(data) {
            if (data.length) {
                this.getData(data);
                this.showData = true;
            } else {
                this.showData = false;
            }
        },
        getData(data) {
            this.getHeaders(data);
            this.getStatisticData(data);
            if (this.timeComparisonXAxis.TotalXAxis) {
                this.getXTotals();
            }
            this.getTotals();
        },
        getHeaders: function (data) {
            for (let i = 0; i < data[0].length; i++) {
                this.headers.push({'ID': i, 'Name': data[0][i]});
            }

        },
        getStatisticData: function (data) {
            for (let i = 1; i < data.length; i++) {
                this.statisticData.push(data[i]);
            }
        },

        getTotals: function () {
            for (let col = 1; col < this.statisticData[0].length; col++) {
                let sum = 0;
                for (let row = 1; row < this.statisticData.length; row++) {
                    let numberValue = '';
                    if(this.timeComparisonXAxis.Dropdown === 'amount'){
                        numberValue = this.statisticData[row][col].replace('kg', '')
                    } else {
                        numberValue = this.statisticData[row][col].replace(' Fr.', '')
                    }
                    sum = parseInt(numberValue) + sum;
                }
                this.statisticTotals.push(sum)
            }
        },
        getXTotals: function () {
            this.headers.push({'ID': this.headers.length + 1, 'Name': 'Total'});
            for (let row = 0; row < this.statisticData.length; row++) {
                let sum = 0;
                for (let col = 1; col < this.statisticData[row].length; col++) {
                    let numberXValue = '';
                    if(this.timeComparisonXAxis.Dropdown === 'amount'){
                        numberXValue = this.statisticData[row][col].replace('kg', '')
                    } else {
                        numberXValue = this.statisticData[row][col].replace(' Fr.', '')
                    }
                    sum = parseInt(numberXValue) + sum;
                }
                this.timeComparisonXAxis.Dropdown === 'amount' ? this.statisticData[row].push(sum + 'kg') : this.statisticData[row].push(sum + ' Fr.');

            }
        },
    }
}
;