import reportsService from "@/services/reports/reports.service";

export default {
    name: "CostJournal",
    props: {
        header: Array,
        filter: Object,
        showTotals: true,
        costsJournalXAxis: Object,
        allData: true
    },
    data() {
        return {
            statisticData: [{
                AccountId: "",
                AccountNr: "",
                AccountName:"",
                organisations:[]
            }]
        }
    },
    async created() {
        await this.getJournalData();
    },
    mounted() {
        this.$root.$on('update_statistics', async () => {
            await this.getJournalData();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
                await this.getJournalData();
            }
        },
        'filter.endDate': async function (value) {
            if (value !== '') {
                await this.getJournalData();
            }
        },
        'filter.accounts': async function () {
            await this.getJournalData();
        },
        'filter.organisations': async function () {
            await this.getJournalData();
        },
        'costsJournalXAxis.Dropdown': async function () {
            await this.getJournalData();
        }
    },
    computed: {
        TotalWeight: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.Weight
                }
            }
            return sum;
        },
        TotalCosts: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.Costs
                }
            }
            return sum.toFixed(2);
        },
        TotalMwst: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.MwstCosts
                }
            }
            return sum.toFixed(2);
        },
        TotalCostsTotal: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.CostsTotal
                }
            }
            return sum.toFixed(2);
        },
        TotalRevenues: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.Revenues
                }
            }
            return sum.toFixed(2);
        },
        TotalRevenuesMwst: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.MwstRevenue
                }
            }
            return sum.toFixed(2);
        },
        TotalRevenuesTotal: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                for (let z = 0; z < this.statisticData[i].organisations.length; z++) {
                    let data = this.statisticData[i].organisations[z];
                    sum = sum + data.RevenuesTotal
                }
            }
            return sum.toFixed(2);
        }
    },
    methods: {
        emptyData() {
            this.statisticData.length = 0;
        },
        async getJournalData() {
            this.emptyData();
            if (this.costsJournalXAxis.Dropdown === 'costs') {
                this.statisticData = await reportsService.getReportCostsJournal(this.filter);
            } else {
                this.statisticData = await reportsService.getReportRevenuesJournal(this.filter);
            }
        }
    }
};
