export default {
    name: "ProfileElement",
    props: {
        profile: Object,
    },
    data() {
        return {
        };
    },
    async created() {

    },
    methods: {
        updateProfile(id) {
            console.log(id);
        },
    },
};
