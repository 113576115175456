import { render, staticRenderFns } from "./CreateDepartment.vue?vue&type=template&id=4e17d371&"
import script from "./CreateDepartment.js?vue&type=script&lang=js&"
export * from "./CreateDepartment.js?vue&type=script&lang=js&"
import style0 from "./CreateDepartment.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports