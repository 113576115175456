export default {
    name: "ContactInfoElement",
    props: {
        contactElement: Object,
        depth: Number,
        organisationName: String,
        departmentName: String
    },
    data() {
        return {
            isHidden: false,
        };
    },
    methods: {
        deleteContact(id) {
            this.$emit("delete_contact_element", id);
        },
        updateContact(id) {
            this.$emit("update_contact_element", id);
        }
    },
};
