// @ is an alias to /src
import { mapGetters } from "vuex";
import StateChangerPopup from "@/components/StateChangerPopup/StateChangerPopup.vue";
import CreatePopup from "@/components/CreatePopup/CreatePopup.vue";

export default {
  name: "Navigation",
  components: {
    CreatePopup,
    StateChangerPopup,
  },
  data() {
    return {
      createPopupIsOpen: false,
      showPopup: false,
      enviroment: {},
    };
  },
  async created() {
    this.enviroment = this.getEnviroment();
  },
  mounted () {
    this.$root.$on('close_create_popup',() => {
      this.createPopupIsOpen = false;
      this.showPopup = false;
    });
  },
  methods: {
    ...mapGetters(["getEnviroment"]),
    toggleCreatePopup() {
      this.createPopupIsOpen = !this.createPopupIsOpen;
    },
    toggleStateChangerPopup() {
      this.showPopup = !this.showPopup;
    },
    updateState() {
      this.$emit("update_state", "");
    },
  },
};
