import ContactInfoElement from "@/components/ContactElement/ContactInfoElement/ContactInfoElement.vue";

export default {
  name: "ContactElement",
  props: {
    contact: Object
  },
  components: {
    ContactInfoElement
  },
  data() {
    return {
        isHidden: false,
    };
  },
  methods: {
    deleteContact(id) {
      this.$root.$emit("delete_contact", id);
    },
    updateContact(id) {
      this.$root.$emit("update_contact", id);
    },
    updateOrganisation(id) {
      this.$root.$emit("update_organisation", id);
    },
    deleteOrganisation(id) {
      this.$root.$emit("delete_organisation", id);
    },
    updateDepartment(id) {
      this.$root.$emit("update_department", id);
    },
    deleteDepartment(id) {
      this.$root.$emit("delete_department", id);
    },
  },
};
