import addressbookService from "@/services/addressbook/addressbook.service";

export default {
    name: "CreateOrganisation",
    components: {},
    props: {
        organisationToUpdate: Object,
    },
    data() {
        return {
            organisation: {},
            department: {},
            organisationIsValid: false,
            departmentIsValid: false,
            allDepartments: [],
            currentStep: "firstStep",
            step: 1,
            isComplete: true,
        };
    },
    computed: {
        departments: function () {
            return this.allDepartments;
        },
    },
    async created() {
        if (this.organisationToUpdate) {
            this.allDepartments = await addressbookService.getDepartmentsByOrganisationId(this.organisationToUpdate.OrganisationId);
            this.organisation = this.organisationToUpdate;
        }
    },
    methods: {
        checkOrganisationHasDepartment(organisationId) {
            let checker = false;
            for (let i = 0; i < this.allDepartments.length; i++) {
                const department = this.allDepartments[i];
                if (department.OrganisationId === organisationId)
                    checker = true;
            }
            return checker;
        },
        async submitStep() {
            ++this.step;
        },
        async submit() {
            if (!this.organisation.OrganisationName) {
                this.organisationIsValid = true;
                return;
            }
            await addressbookService.createOrganisationWithoutMainContact(this.organisation);
            this.clearOrganisation();
            this.$root.$emit('refresh_entry');
            this.$root.$emit("close_create_popup");
        },
        async update() {
            if (!this.organisation.OrganisationName) {
                this.organisationIsValid = true;
                return;
            }
            await addressbookService.updateOrganisationWithoutMainContact(this.organisation);
            this.clearOrganisation();
            this.$emit("close_update_popup", true);
            this.$root.$emit('refresh_entry');
        },
        clearOrganisation() {
            this.organisation.OrganisationName = null;
            this.organisation.WebPage = null;
            this.organisation.BusinessPhone = null;
            this.organisation.FaxNumber = null;
            this.organisation.Street1 = null;
            this.organisation.StreetNumber = null;
            this.organisation.Street2 = null;
            this.organisation.City = null;
            this.organisation.PostalCode = null;
            this.organisation.Country = null;
            this.organisationIsValid = false;
        },
        async createDepartment() {
            if (!this.department.name) {
                this.departmentIsValid = true;
                return;
            }
            this.department.organisationId = this.organisation.OrganisationId;
            await addressbookService.createDepartment(this.department);

            this.department.name = null;
            this.department.organisationId = null;
            this.department.BusinessPhone = null;
            this.department.fax = null;
            this.department.street = null;
            this.department.streetNumber = null;
            this.department.street2 = null;
            this.department.country = null;
            this.department.city = null;
            this.department.postalCode = null;

            this.departmentIsValid = false;
            this.allDepartments = await addressbookService.getDepartmentsByOrganisationId(this.organisationToUpdate.OrganisationId);
            this.step = 2;
            this.$root.$emit("refresh_entry");
        },
        async deleteDepartment(id) {
            try {
                await addressbookService.deleteDepartment(id);
                this.allDepartments = await addressbookService.getDepartments();
                this.$root.$emit("delete_entry");
            } catch (e) {
                console.log(e);
            }
        },
    },
};
