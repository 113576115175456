import httpService from "../http.service";
import authorizationService from "../authorization.service";
import Cookies from "js-cookie";
import yearsService from "@/services/years/years.service";

const templatesService = {
    async getTemplates() {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        await httpService
            .get(`/report/template`, authConfig)
            .then((response) => (res = response.data))
            .catch((error) => console.log(error));
        return res;
    },
    async getReportTotalBalance(filter) {
        let res;
        const community = Cookies.get("enviromentCommune");
        const communityId = JSON.parse(community).CommunityId;
        const year = Cookies.get("enviromentYear");
        const yearObject = await yearsService.getYearByYear(year);
        const yearId = yearObject[0].YearId;
        const authConfig = await authorizationService.getNewHeader();
        const filterParam = new URLSearchParams(filter).toString();
        await httpService
            .get(`/report/balance?commune=${communityId}&year=${yearId}&${filterParam}`, authConfig)
            .then((response) => {
                res = response.data
            }).catch((error) => console.log(error));

        return res;
    },
    async getReportCostsJournal(filter) {
        let res;
        const community = Cookies.get("enviromentCommune");
        const communityId = JSON.parse(community).CommunityId;
        const year = Cookies.get("enviromentYear");
        const yearObject = await yearsService.getYearByYear(year);
        const yearId = yearObject[0].YearId;
        const authConfig = await authorizationService.getNewHeader();
        const filterParam = new URLSearchParams(filter).toString();
        await httpService
            .get(`/report/journal/costs?commune=${communityId}&year=${yearId}&${filterParam}`, authConfig)
            .then((response) => {
                res = response.data
            }).catch((error) => console.log(error));

        return res;
    },
    async getReportOrganisationTotalJournal(filter) {
        let res;
        const community = Cookies.get("enviromentCommune");
        const communityId = JSON.parse(community).CommunityId;
        const year = Cookies.get("enviromentYear");
        const yearObject = await yearsService.getYearByYear(year);
        const yearId = yearObject[0].YearId;
        const authConfig = await authorizationService.getNewHeader();
        const filterParam = new URLSearchParams(filter).toString();
        await httpService
            .get(`/report/journal/organisation/total?commune=${communityId}&year=${yearId}&${filterParam}`, authConfig)
            .then((response) => {
                res = response.data
            }).catch((error) => console.log(error));

        return res;
    },
    async getReportRevenuesJournal(filter) {
        let res;
        const community = Cookies.get("enviromentCommune");
        const communityId = JSON.parse(community).CommunityId;
        const year = Cookies.get("enviromentYear");
        const yearObject = await yearsService.getYearByYear(year);
        const yearId = yearObject[0].YearId;
        const authConfig = await authorizationService.getNewHeader();
        const filterParam = new URLSearchParams(filter).toString();
        await httpService
            .get(`/report/journal/revenues?commune=${communityId}&year=${yearId}&${filterParam}`, authConfig)
            .then((response) => {
                res = response.data
            }).catch((error) => console.log(error));

        return res;
    },
    async getReportTimeComparison(filter,timeComparisonXAxis) {
        let res;
        const community = Cookies.get("enviromentCommune");
        const communityId = JSON.parse(community).CommunityId;
        const year = Cookies.get("enviromentYear");
        const yearObject = await yearsService.getYearByYear(year);
        const yearId = yearObject[0].YearId;
        const authConfig = await authorizationService.getNewHeader();
        const filterParam = new URLSearchParams();
        filterParam.append("accounts", filter.accounts);
        filterParam.append("organisations", filter.organisations);
        filterParam.append("startDate", filter.startDate);
        filterParam.append("endDate", filter.endDate);
        filterParam.append("TimeComparisonXAxisDropdown", timeComparisonXAxis.Dropdown);
        filterParam.append("TimeComparisonXAxisPerMonthXAxis", timeComparisonXAxis.PerMonthXAxis);
        filterParam.append("TimeComparisonXAxisPerYearXAxis", timeComparisonXAxis.PerYearXAxis);
        filterParam.append("TimeComparisonXAxisTotalXAxis", timeComparisonXAxis.TotalXAxis);
        await httpService
            .get(`/report/time?commune=${communityId}&year=${yearId}&${filterParam}`, authConfig)
            .then((response) => {
                res = response.data
            }).catch((error) => console.log(error));
        return res;
    },
    async createReport(report) {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        const params = new URLSearchParams();
        params.append("ReportName", report.ReportName);
        params.append("ReportCategory", report.ReportCategory);
        params.append("StartDate", report.StartDate);
        params.append("EndDate", report.EndDate);
        params.append("Accounts", report.Accounts);
        params.append("Organisations", report.Organisations);
        params.append("TotalBalanceXAxisWasteAmount", report.TotalBalanceXAxisWasteAmount);
        params.append("TotalBalanceXAxisWasteAmountKg", report.TotalBalanceXAxisWasteAmountKg);
        params.append("TotalBalanceXAxisWasteAmountPercent", report.TotalBalanceXAxisWasteAmountPercent);
        params.append("TotalBalanceXAxisCosts", report.TotalBalanceXAxisCosts);
        params.append("TotalBalanceXAxisCostsFr", report.TotalBalanceXAxisCostsFr);
        params.append("TotalBalanceXAxisCostsPercent", report.TotalBalanceXAxisCostsPercent);
        params.append("TotalBalanceXAxisCostsFrT", report.TotalBalanceXAxisCostsFrT);
        params.append("TotalBalanceXAxisRevenues", report.TotalBalanceXAxisRevenues);
        params.append("TotalBalanceXAxisRevenuesFr", report.TotalBalanceXAxisRevenuesFr);
        params.append("TotalBalanceXAxisRevenuesPercent", report.TotalBalanceXAxisRevenuesPercent);
        params.append("TotalBalanceXAxisRevenuesFrT", report.TotalBalanceXAxisRevenuesFrT);
        params.append("TotalBalanceXAxisCostRecovery", report.TotalBalanceXAxisCostRecovery);
        params.append("CostsJournalXAxisDropdown", report.CostsJournalXAxisDropdown);
        params.append("CostsJournalXAxisWasteAmountKg", report.CostsJournalXAxisWasteAmountKg);
        params.append("CostsJournalXAxisCosts", report.CostsJournalXAxisCosts);
        params.append("CostsJournalXAxisCostsMwSt", report.CostsJournalXAxisCostsMwSt);
        params.append("CostsJournalXAxisCostsTotal", report.CostsJournalXAxisCostsTotal);
        params.append("TimeComparisonXAxisDropdown", report.TimeComparisonXAxisDropdown);
        params.append("TimeComparisonXAxisPerMonthXAxis", report.TimeComparisonXAxisPerMonthXAxis);
        params.append("TimeComparisonXAxisPerYearXAxis", report.TimeComparisonXAxisPerYearXAxis);
        params.append("TimeComparisonXAxisTotalXAxis", report.TimeComparisonXAxisTotalXAxis);

        await httpService
            .post("/report/template", params, authConfig)
            .then((response) => {
                res = response.data;
            })
            .catch((error) => console.log(error));
        return res;
    },

    async deleteTemplate(id) {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        await httpService
            .delete("/report/template/" + id, authConfig)
            .then((response) => {
                res = response.data;
            })
            .catch((error) => console.log(error));
        return res;
    },

    async excelExport(report, filter, totalBalanceXAxis, costsJournalXAxis, timeComparisonXAxis) {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        const params = new URLSearchParams();
        const community = Cookies.get("enviromentCommune");
        const communityId = JSON.parse(community).CommunityId;
        const year = Cookies.get("enviromentYear");
        const yearObject = await yearsService.getYearByYear(year);
        const yearId = yearObject[0].YearId;

        params.append("ReportName", report.ReportName);
        params.append("ReportCategory", report.ReportCategory);
        params.append("accounts", filter.accounts);
        params.append("organisations", filter.organisations);
        params.append("startDate", filter.startDate);
        params.append("endDate", filter.endDate);
        params.append("TotalBalanceXAxisWasteAmount", totalBalanceXAxis.WasteAmount);
        params.append("TotalBalanceXAxisWasteAmountKg", totalBalanceXAxis.WasteAmountKg);
        params.append("TotalBalanceXAxisWasteAmountPercent", totalBalanceXAxis.WasteAmountPercent);
        params.append("TotalBalanceXAxisCosts", totalBalanceXAxis.Costs);
        params.append("TotalBalanceXAxisCostsFr", totalBalanceXAxis.CostsFr);
        params.append("TotalBalanceXAxisCostsPercent", totalBalanceXAxis.CostsPercent);
        params.append("TotalBalanceXAxisCostsFrT", totalBalanceXAxis.CostsFrT);
        params.append("TotalBalanceXAxisRevenues", totalBalanceXAxis.Revenues);
        params.append("TotalBalanceXAxisRevenuesFr", totalBalanceXAxis.RevenuesFr);
        params.append("TotalBalanceXAxisRevenuesPercent", totalBalanceXAxis.RevenuesPercent);
        params.append("TotalBalanceXAxisRevenuesFrT", totalBalanceXAxis.RevenuesFrT);
        params.append("TotalBalanceXAxisCostRecovery", totalBalanceXAxis.CostRecovery);
        params.append("CostsJournalXAxisDropdown", costsJournalXAxis.Dropdown);
        params.append("CostsJournalXAxisWasteAmountKg", costsJournalXAxis.WasteAmountKg);
        params.append("CostsJournalXAxisCosts", costsJournalXAxis.Costs);
        params.append("CostsJournalXAxisCostsMwSt", costsJournalXAxis.CostsMwSt);
        params.append("CostsJournalXAxisCostsTotal", costsJournalXAxis.CostsTotal);
        params.append("TimeComparisonXAxisDropdown", timeComparisonXAxis.Dropdown);
        params.append("TimeComparisonXAxisPerMonthXAxis", timeComparisonXAxis.PerMonthXAxis);
        params.append("TimeComparisonXAxisPerYearXAxis", timeComparisonXAxis.PerYearXAxis);
        params.append("TimeComparisonXAxisTotalXAxis", timeComparisonXAxis.TotalXAxis);
        params.append("commune", communityId);
        params.append("year", yearId);

        await httpService
            .post(`/report/excel`, params, authConfig)
            .then((response) => {
                res = response.data;
            })
            .catch((error) => console.log(error));
        return res;
    },
    async updateTemplate(report) {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        const params = new URLSearchParams();
        params.append("ReportName", report.ReportName);
        params.append("ReportCategory", report.ReportCategory);
        params.append("StartDate", report.StartDate);
        params.append("EndDate", report.EndDate);
        params.append("Accounts", report.Accounts);
        params.append("Organisations", report.Organisations);
        params.append("TotalBalanceXAxisWasteAmount", report.TotalBalanceXAxisWasteAmount);
        params.append("TotalBalanceXAxisWasteAmountKg", report.TotalBalanceXAxisWasteAmountKg);
        params.append("TotalBalanceXAxisWasteAmountPercent", report.TotalBalanceXAxisWasteAmountPercent);
        params.append("TotalBalanceXAxisCosts", report.TotalBalanceXAxisCosts);
        params.append("TotalBalanceXAxisCostsFr", report.TotalBalanceXAxisCostsFr);
        params.append("TotalBalanceXAxisCostsPercent", report.TotalBalanceXAxisCostsPercent);
        params.append("TotalBalanceXAxisCostsFrT", report.TotalBalanceXAxisCostsFrT);
        params.append("TotalBalanceXAxisRevenues", report.TotalBalanceXAxisRevenues);
        params.append("TotalBalanceXAxisRevenuesFr", report.TotalBalanceXAxisRevenuesFr);
        params.append("TotalBalanceXAxisRevenuesPercent", report.TotalBalanceXAxisRevenuesPercent);
        params.append("TotalBalanceXAxisRevenuesFrT", report.TotalBalanceXAxisRevenuesFrT);
        params.append("TotalBalanceXAxisCostRecovery", report.TotalBalanceXAxisCostRecovery);
        params.append("CostsJournalXAxisDropdown", report.CostsJournalXAxisDropdown);
        params.append("CostsJournalXAxisWasteAmountKg", report.CostsJournalXAxisWasteAmountKg);
        params.append("CostsJournalXAxisCosts", report.CostsJournalXAxisCosts);
        params.append("CostsJournalXAxisCostsMwSt", report.CostsJournalXAxisCostsMwSt);
        params.append("CostsJournalXAxisCostsTotal", report.CostsJournalXAxisCostsTotal);
        params.append("TimeComparisonXAxisDropdown", report.TimeComparisonXAxisDropdown);
        params.append("TimeComparisonXAxisPerMonthXAxis", report.TimeComparisonXAxisPerMonthXAxis);
        params.append("TimeComparisonXAxisPerYearXAxis", report.TimeComparisonXAxisPerYearXAxis);
        params.append("TimeComparisonXAxisTotalXAxis", report.TimeComparisonXAxisTotalXAxis);

        await httpService
            .put("/report/template/" + report.ReportId, params, authConfig)
            .then((response) => {
                res = response.data;
            })
            .catch((error) => console.log(error));
        return res;
    },
};
export default templatesService;
