// @ is an alias to /src
import CreateBill from "@/components/CreatePopup/Sections/CreateBill/CreateBill.vue";
import CreateAccount from "@/components/CreatePopup/Sections/CreateAccount/CreateAccount.vue";
import CreateAddress from "@/components/CreatePopup/Sections/CreateAddress/CreateAddress.vue";
import CreateCommune from "@/components/CreatePopup/Sections/CreateCommune/CreateCommune.vue";
import CreateYear from "@/components/CreatePopup/Sections/CreateYear/CreateYear.vue";
import CreatePassword from "@/components/CreatePopup/Sections/CreatePassword/CreatePassword.vue";
import CreateOrganisation from "@/components/CreatePopup/Sections/CreateOrganisation/CreateOrganisation.vue";

export default {
  name: "CreatePopup",
  props: {
    state: String,
  },
  components: {
    CreateBill,
    CreateAccount,
    CreateAddress,
    CreateCommune,
    CreateYear,
    CreatePassword,
    CreateOrganisation
  },
  data() {
    return {
      current: "",
    };
  },
  computed: {
    // a computed getter
    activeTitle: function () {
      switch (this.current) {
        case "return":
          return "Neue Einnahme";
        case "account":
          return "Neues Konto";
        case "address":
          return "Neuer Kunde";
        case "commune":
          return "Neue Gemeinde";
        case "year":
          return "Neues Jahr";
        case "organisation":
          return "Neue Organisation";
        default:
          return "Neue Ausgabe";
      }
    },
  },
  async created() {
    this.current = this.state;
  },
  methods: {
    close() {
      this.$emit("close_popup", true);
    },
  },
};

