import reportsService from "@/services/reports/reports.service";

export default {
    name: "TotalBalance",
    props: {
        header: Array,
        showTotals: true,
        totalBalanceXAxis: Object,
        filter: Object,
        allData: true
    },
    data() {
        return {
            statisticData: {
                AccountNr: 0,
                AccountName: 0,
                WasteAmountKg: 0,
                WasteAmountPercent: 0,
                CostsFr: 0,
                CostsPercent: 0,
                CostsFrT: 0,
                RevenuesFr: 0,
                RevenuesPercent: 0,
                RevenuesFrT: 0,
                CostRecoveryFr: 0
            },
            WasteAmountCount: 2,
            CostsCount:3,
            RevenuesCount:3
        }
    },
    async created() {
        this.statisticData = await reportsService.getReportTotalBalance();
    },
    mounted() {
        this.$root.$on('update_statistics', async () => {
            this.statisticData = await reportsService.getReportTotalBalance();
        });
    },
    watch:{
        allData: async function (value){
            if(value){
                this.filter.startDate = '';
                this.filter.endDate = '';
            }
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        },
        'filter.accounts': async function (){
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        },
        'filter.startDate': async function (){
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        },
        'filter.endDate': async function (){
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        },
        'totalBalanceXAxis.WasteAmount': function(value){
            if(value){
                this.totalBalanceXAxis.WasteAmountKg = true;
                this.totalBalanceXAxis.WasteAmountPercent = true;
            } else {
                this.totalBalanceXAxis.WasteAmountKg = false;
                this.totalBalanceXAxis.WasteAmountPercent = false;
            }
        },
        'totalBalanceXAxis.WasteAmountKg': function(value){
            value ? this.WasteAmountCount += 1 : this.WasteAmountCount -= 1;
        },
        'totalBalanceXAxis.WasteAmountPercent': function(value){
            value ? this.WasteAmountCount += 1 : this.WasteAmountCount -= 1;
        },
        'WasteAmountCount': function(value){
            value === 0 ? this.totalBalanceXAxis.WasteAmount = false : this.totalBalanceXAxis.WasteAmount = true;
        },
        'totalBalanceXAxis.Costs': function(value){
            if(value){
                this.totalBalanceXAxis.CostsFr = true;
                this.totalBalanceXAxis.CostsPercent = true;
                this.totalBalanceXAxis.CostsFrT = true;
            } else {
                this.totalBalanceXAxis.CostsFr = false;
                this.totalBalanceXAxis.CostsPercent = false;
                this.totalBalanceXAxis.CostsFrT = false;
            }
        },
        'totalBalanceXAxis.CostsFr': function(value){
            value ? this.CostsCount += 1 : this.CostsCount -= 1;
        },
        'totalBalanceXAxis.CostsPercent': function(value){
            value ? this.CostsCount += 1 : this.CostsCount -= 1;
        },
        'totalBalanceXAxis.CostsFrT': function(value){
            value ? this.CostsCount += 1 : this.CostsCount -= 1;
        },
        'CostsCount': function(value){
            value === 0 ? this.totalBalanceXAxis.Costs = false : this.totalBalanceXAxis.Costs = true;
        },
        'totalBalanceXAxis.Revenues': function(value){
            if(value){
                this.totalBalanceXAxis.RevenuesFr = true;
                this.totalBalanceXAxis.RevenuesPercent = true;
                this.totalBalanceXAxis.RevenuesFrT = true;
            } else {
                this.totalBalanceXAxis.RevenuesFr = false;
                this.totalBalanceXAxis.RevenuesPercent = false;
                this.totalBalanceXAxis.RevenuesFrT = false;
            }
        },
        'totalBalanceXAxis.RevenuesFr': function(value){
            value ? this.RevenuesCount += 1 : this.RevenuesCount -= 1;
        },
        'totalBalanceXAxis.RevenuesPercent': function(value){
            value ? this.RevenuesCount += 1 : this.RevenuesCount -= 1;
        },
        'totalBalanceXAxis.RevenuesFrT': function(value){
            value ? this.RevenuesCount += 1 : this.RevenuesCount -= 1;
        },
        'RevenuesCount': function(value){
            value === 0 ? this.totalBalanceXAxis.Revenues = false : this.totalBalanceXAxis.Revenues = true;
        }
    },
    computed: {
        TotalWeight: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                let data = this.statisticData[i];
                sum = sum + data.WasteAmountKg
            }
            return sum;
        },
        TotalCostsFr: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                let data = this.statisticData[i];
                sum = sum + data.CostsFr
            }
            return sum.toFixed(2);
        },
        TotalCostsFrT: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                let data = this.statisticData[i];
                sum = sum + data.CostsFrT
            }
            return sum.toFixed(2);
        },
        TotalRevenuesFr: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                let data = this.statisticData[i];
                sum = sum + data.RevenuesFr
            }
            return sum.toFixed(2);
        },
        TotalRevenuesFrT: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                let data = this.statisticData[i];
                sum = sum + data.RevenuesFrT
            }
            return sum.toFixed(2);
        },
        TotalCostRecoveryFr: function () {
            let sum = 0;
            for (let i = 0; i < this.statisticData.length; i++) {
                let data = this.statisticData[i];
                sum = sum + data.CostRecoveryFr
            }
            return sum.toFixed(2);
        }
    }
};
