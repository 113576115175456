import httpService from "./http.service";
import store from "../store/index";

const authorizationService = {
  user: store.getters.getCurrentUser,

  async getToken(userName = this.user.userName, password = this.user.password) {
    let res;
    await httpService
      .get(`/login?UserName=${userName}&Password=${password}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res["jwt"];
  },

  async getNewHeader() {
    const config = {
      headers: {
        Authorization: "Bearer " + (await authorizationService.getToken()),
      },
    };
    return config;

  },
};
export default authorizationService;
