// @ is an alias to /src
import ContactElement from "@/components/ContactElement/ContactElement.vue";
import addressbookService from "@/services/addressbook/addressbook.service";

export default {
    name: "Contacts",
    components: {
        ContactElement
    },
    data() {
        return {
            addressbook: {},
            currentLetter: "",
            currentSearch: "",
            contactId: 0
        };
    },
    async created() {
        this.addressbook = await addressbookService.getOrganisationContacts();
    },
    computed: {
        letter: function () {
            return this.currentLetter;
        },
    },
    mounted () {
        this.$root.$on('delete_entry_contact', async (id) => {
            await addressbookService.deleteContact(id);
            this.addressbook = await addressbookService.getOrganisationContacts();
            this.$emit("open_message_box", 'danger');
        });
        this.$root.$on('delete_entry_organisation', async (id) => {
            await addressbookService.deleteOrganisation(id);
            this.addressbook = {};
            this.addressbook = await addressbookService.getOrganisationContacts();
            this.$emit("open_message_box", 'danger');
        });
        this.$root.$on('delete_entry_department', async (id) => {
            await addressbookService.deleteDepartment(id);
            this.addressbook = await addressbookService.getOrganisationContacts();
            this.$emit("open_message_box", 'danger');
        });
        this.$root.$on('refresh_entry', async () => {
            this.addressbook = await addressbookService.getOrganisationContacts();
            this.$emit("open_message_box", 'success');
        });
        this.$root.$on('update_addressBook', async () => {
            this.addressbook = await addressbookService.getOrganisationContacts();
        });
        this.$root.$on('delete_entry', () => {
            this.$emit("open_message_box", 'danger');
        });
        this.$root.$on('update_contact', async (id) => {
           await this.updateContact(id)
        });
        this.$root.$on('delete_contact', async (id) => {
            await this.deleteContact(id)
        });
        this.$root.$on('update_organisation', async (id) => {
            await this.updateOrganisation(id)
        });
        this.$root.$on('delete_organisation', async (id) => {
            await this.deleteOrganisation(id)
        });
        this.$root.$on('update_department', async (id) => {
            await this.updateDepartment(id)
        });
        this.$root.$on('delete_department', async (id) => {
            await this.deleteDepartment(id)
        });
    },
    methods: {
        printLetter(str, str2) {
            if (str.charAt(0) == str2.charAt(0)) {
                return false;
            }
            return true;
        },
        getLetter(str) {
            return str.charAt(0);
        },
        deleteContact(id) {
            this.$emit("open_delete_popup", id, 'contact');
        },
        async updateContact(id) {
            this.$emit("open_update_contact", id);
        },
        deleteOrganisation(id) {
            this.$emit("open_delete_popup", id, 'organisation');
        },
        async updateOrganisation(id) {
            this.$emit("open_update_organisation", id);
        },
        deleteDepartment(id) {
            this.$emit("open_delete_popup", id, 'department');
        },
        async updateDepartment(id) {
            this.$emit("open_update_department", id);
        }
    },
};
