import httpService from "../http.service";
import authorizationService from "../authorization.service";
import Cookies from "js-cookie";

const billsService = {
  async getBills(limit, search, filter, asExcel = false) {
    let res;
    const year = Cookies.get("enviromentYear");
    const community = Cookies.get("enviromentCommune");
    const communityId = JSON.parse(community).CommunityId;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(
        `/bill?year=${year}&commune=${communityId}&limit=${limit}&search=${search}&filter=${filter}&AsExcel=${asExcel}`,
        authConfig
      )
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },

  async getBill(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/bill/${id}`, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  async copyBill(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
        .get(`/bill/copy/${id}`, authConfig)
        .then((response) => {
          res = response.data;
        })
        .catch((error) => {
          console.log(error);
          res = null;
        });
    return res;
  },
  async createBill(bill) {
    let res;
    const params = new URLSearchParams();
    const authConfig = await authorizationService.getNewHeader();
    params.append("CommunityId", bill.CommunityId);
    params.append("OrganisationId", bill.OrganisationId);
    params.append("DepartmentId", bill.DepartmentId);
    params.append("AccountId", bill.AccountId);
    params.append("Year", bill.Year);
    params.append("InvoiceDate", bill.InvoiceDate);
    params.append("DueDate", bill.DueDate);
    params.append("InvoiceReference", bill.InvoiceReference);
    params.append("InvoiceType", bill.InvoiceType);
    params.append("PieceNumber", bill.PieceNumber);
    params.append("CubicMetres", bill.CubicMetres);
    params.append("Weight", bill.Weight);
    params.append("Costs", bill.Costs);
    params.append("MwstCosts", bill.MwstCosts);
    params.append("Revenues", bill.Revenues);
    params.append("MwstRevenue", bill.MwstRevenue);

    await httpService
      .post("/bill", params, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },

  async deleteBill(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/bill/" + id, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },

  async updateBill(bill) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    const params = new URLSearchParams();
    params.append("CommunityId", bill.CommunityId);
    params.append("OrganisationId", bill.OrganisationId);
    params.append("DepartmentId", bill.DepartmentId);
    params.append("AccountId", bill.AccountId);
    params.append("YearId", bill.YearId);
    params.append("InvoiceDate", bill.InvoiceDate);
    params.append("DueDate", bill.DueDate);
    params.append("InvoiceReference", bill.InvoiceReference);
    params.append("InvoiceType", bill.InvoiceType);
    params.append("PieceNumber", bill.PieceNumber);
    params.append("CubicMetres", bill.CubicMetres);
    params.append("Weight", bill.Weight);
    params.append("Costs", bill.Costs);
    params.append("MwstCosts", bill.MwstCosts);
    params.append("Revenues", bill.Revenues);
    params.append("MwstRevenue", bill.MwstRevenue);

    await httpService
      .put("/bill/" + bill.BillId, params, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
};
export default billsService;
