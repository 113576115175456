export default {
    name: "DeleteUpdate",
    props: {
        propDeleteId: Number,
        propType: String
    },
    data() {
        return {
            deletePopupIsOpen: false,
            showPopup: false,
            enviroment: {},
        };
    },
    methods: {
        close() {
            this.$emit("close_delete_popup", true);
        },
        deleteContact(id, dataType){
            if(dataType === 'contact')
                this.$root.$emit("delete_entry_contact",id);
            if(dataType === 'bill')
                this.$root.$emit("delete_entry_bill",id);
            if(dataType === 'template'){
                this.$root.$emit("delete_entry_template",id);
            }
            if(dataType === 'organisation'){
                this.$root.$emit("delete_entry_organisation",id);
            }
            if(dataType === 'department'){
                this.$root.$emit("delete_entry_department",id);
            }
            this.close();
        }
    },
};
