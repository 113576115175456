import httpService from "../http.service";
import authorizationService from "../authorization.service";

const communesService = {
  async getComunes() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/commune`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => console.log(error));
    return res;
  },

  async getComune(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/commune/${id}`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => console.log(error));
    return res;
  },
  async createCommune(commune) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("Name", commune.name);
    bodyFormData.append("PostalCode", commune.postalCode);
    bodyFormData.append("Picture", commune.picture);
    bodyFormData.append("Color", commune.color);
    bodyFormData.append("MainContact", commune.mainContact);
    bodyFormData.append("Phone", commune.phone);
    bodyFormData.append("MailAddress", commune.mailAddress);

    await httpService
      .post("/commune", bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },

  async updateCommune(commune) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("Name", commune.name);
    bodyFormData.append("PostalCode", commune.postalCode);
    bodyFormData.append("Picture", commune.picture);
    bodyFormData.append("Color", commune.color);
    bodyFormData.append("MainContact", commune.mainContact);
    bodyFormData.append("Phone", commune.phone);
    bodyFormData.append("MailAddress", commune.mailAddress);

    await httpService
      .put(`/commune/${commune.communeId}`, bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },

  async deleteCommune(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/commune/" + id, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
};
export default communesService;
