import httpService from "../http.service";
import Cookies from "js-cookie";
import authorizationService from "../authorization.service";

const returnsService = {
  async getReturns(limit, search, filter, asExcel = false) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    const year = Cookies.get("enviromentYear");
    const community = Cookies.get("enviromentCommune");
    const communityId = JSON.parse(community).CommunityId;
    await httpService
      .get(
        `/2/bill?year=${year}&commune=${communityId}&limit=${limit}&search=${search}&filter=${filter}&AsExcel=${asExcel}`,
        authConfig
      )
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
};
export default returnsService;
