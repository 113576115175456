import communesService from "@/services/communes/communes.service";
import addressbookService from "../../../../services/addressbook/addressbook.service";

export default {
  name: "CreateCommune",
  components: {},
  data() {
    return {
      currentCommunity: {
        name: null,
        postalCode: null,
        picture: null, //TODO Fileupload
        color: "#dddddd",
        mainContact: null,
        phone: null,
        mailAddress: null,
        communeId: null,
      },

      isComplete: true,
      communes: {},
      addresses: [],
      updateMode: false,
    };
  },
  async created() {
    this.communes = await communesService.getComunes();
    this.addresses = await addressbookService.getAdresses();
  },
  methods: {
    async submit() {
      if (
        !this.currentCommunity.name ||
        !this.currentCommunity.postalCode ||
        !this.currentCommunity.picture ||
        !this.currentCommunity.color ||
        !this.currentCommunity.mainContact ||
        !this.currentCommunity.phone ||
        !this.currentCommunity.mailAddress
      ) {
        this.isComplete = false;
        return;
      }
      await communesService.createCommune(this.currentCommunity);
      this.communes = await communesService.getComunes();
      this.currentCommunity.name = null;
      this.currentCommunity.postalCode = null;
      this.currentCommunity.picture = null;
      this.currentCommunity.color = "#dddddd";
      this.currentCommunity.mainContact = null;
      this.currentCommunity.phone = null;
      this.currentCommunity.mailAddress = null;
      this.isComplete = true;
      this.$root.$emit("refresh_entry");
    },

    async openEdit(id) {
      let communeToEdit = await communesService.getComune(id);
      this.currentCommunity.name = communeToEdit[0].Name;
      this.currentCommunity.postalCode = communeToEdit[0].PostalCode;
      this.currentCommunity.picture = communeToEdit[0].Picture;
      this.currentCommunity.color = communeToEdit[0].Color;
      this.currentCommunity.mainContact = communeToEdit[0].MainContact;
      this.currentCommunity.phone = communeToEdit[0].Phone;
      this.currentCommunity.mailAddress = communeToEdit[0].MailAddress;
      this.currentCommunity.communeId = communeToEdit[0].CommunityId;

      this.updateMode = true;
    },

    async update() {
      if (
        !this.currentCommunity.name ||
        !this.currentCommunity.postalCode ||
        !this.currentCommunity.picture ||
        !this.currentCommunity.color ||
        !this.currentCommunity.mainContact ||
        !this.currentCommunity.phone ||
        !this.currentCommunity.mailAddress
      ) {
        this.isComplete = false;
        return;
      }
      await communesService.updateCommune(this.currentCommunity);
      this.communes = await communesService.getComunes();
      this.currentCommunity.name = null;
      this.currentCommunity.postalCode = null;
      this.currentCommunity.picture = null;
      this.currentCommunity.color = "#dddddd";
      this.currentCommunity.mainContact = null;
      this.currentCommunity.phone = null;
      this.currentCommunity.mailAddress = null;
      this.currentCommunity.communeId = null;
      this.updateMode = false;
      this.isComplete = true;
      this.$root.$emit("refresh_entry");
    },
    async deleteCommune(id) {
      await communesService.deleteCommune(id);
      this.communes = await communesService.getComunes();
      this.$root.$emit("delete_entry");
    },
  },
};
