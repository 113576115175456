import addressbookService from "@/services/addressbook/addressbook.service";
export default {
    name: "CreateDepartment",
    components: {},
    props: {
        departmentToUpdate: Object,
    },
    data() {
        return {
            department: {},
            isComplete: true,
            departmentIsValid: false
        };
    },
    async created() {
        if (this.departmentToUpdate){
            this.department = this.departmentToUpdate;
        }
    },
    methods: {
        async update() {
            if (!this.department.DepartmentName) {
                this.departmentIsValid = true;
                return;
            }
            await addressbookService.updateDepartmentWithoutMainContact(this.department);
            this.department.DepartmentName = null;
            this.department.BusinessPhone = null;
            this.department.FaxNumber = null;
            this.department.Street1 = null;
            this.department.StreetNumber = null;
            this.department.Street2 = null;
            this.department.Country = null;
            this.department.City = null;

            this.department.PostalCode = null;
            this.departmentIsValid = false;


            this.$emit("close_update_popup", true);
            this.$root.$emit('refresh_entry');
        },
    },
};
