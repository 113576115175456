import usersService from "@/services/users/users.service";
export default {
    name: "CreateAddress",
    components: {},
    props: {
    },
    data() {
        return {
            passwords: {
                id: 1,
                password: null,
                passwordConfirm: null
            },
            isComplete: true,
        };
    },
    async created() {
    },
    methods: {
        async update() {
            if (!this.passwords.password || !this.passwords.passwordConfirm || this.passwords.password !== this.passwords.passwordConfirm) {
                this.isComplete = false;
                return;
            }
            await usersService.updateUser(this.passwords);
            this.refresh();
            this.$root.$emit("refresh_entry");
            setTimeout(()=>{
                window.location.href = '/';
            },1000);
        },
        refresh(){
            this.passwords.password = null;
            this.passwords.passwordConfirm = null;
        }
    },
};
