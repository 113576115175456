export default {
    name: "MessageBox",
    props:{
        messageType: String,
    },
    created() {
        setTimeout(() => this.close(),6000);
    },
    methods: {
        close() {
            this.$emit("close_message_box", true);
        }
    },
};
