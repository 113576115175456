// initial state
const state = {
  isLoggedIn: false,
  currentUser: {
    userName: "",
    password: "",
  },
};
// getters
const getters = {
  getIsLoggedIn: (state) => state.isLoggedIn,
  getCurrentUser: (state) => state.currentUser,
};

// mutations
const mutations = {
  SET_CURRENT_USER(state, value) {
    state.currentUser.userName = value.userName;
    state.currentUser.password = value.password;
    state.isLoggedIn = true;
  },
};

// actions
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
