// @ is an alias to /src

export default {
  name: "Loader",
  components: {},
  data() {
    return {};
  },
  created() {},
};
