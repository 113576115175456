import Cookies from "js-cookie";
// initial state
const state = {
  currentEnviroment: {
    currentYear: 0,
    currentCommunity: {},
  },
};
// getters
const getters = {
  getEnviroment: (state) => state.currentEnviroment,
  getYear: (state) => state.currentEnviroment.currentYear,
  getCommunity: (state) => state.currentEnviroment.currentCommunity,
};

// mutations
const mutations = {
  SET_ENVIROMENT(state, value) {
    state.currentEnviroment = value;
    Cookies.set("enviromentYear", value.currentYear);
    Cookies.set("enviromentCommune", JSON.stringify(value.currentCommunity));
  },
  SET_YEAR(state, value) {
    state.currentEnviroment.currentYear = value;
    Cookies.set("enviromentYear", value);
  },
  SET_COMMUNITY(state, value) {
    state.currentEnviroment.currentCommunity = value;
    Cookies.set("enviromentCommune", JSON.stringify(value));
  },
};

// actions
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
