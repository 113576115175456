import { render, staticRenderFns } from "./TotalBalance.vue?vue&type=template&id=52688801&"
import script from "./TotalBalance.js?vue&type=script&lang=js&"
export * from "./TotalBalance.js?vue&type=script&lang=js&"
import style0 from "./TotalBalance.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports