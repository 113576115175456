import httpService from "../http.service";
import authorizationService from "../authorization.service";

const accountsService = {
  async getAccounts() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/account`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => console.log(error));
    return res;
  },

  async createAccount(account) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("AccountNr", account.accountNr);
    bodyFormData.append("AccountName", account.accountName);
    bodyFormData.append("AccountClass", account.accountClass);
    bodyFormData.append("FinanceAccount", account.financeAccount);

    await httpService
      .post("/account", bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async deleteAccount(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/account/" + id, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async updateAccount(account) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    const params = new URLSearchParams();
    params.append("AccountId", account.accountId);
    params.append("AccountNr", account.accountNr);
    params.append("AccountName", account.accountName);
    params.append("AccountClass", account.accountClass);
    params.append("FinanceAccount", account.financeAccount);

    await httpService
      .put("/account/" + account.accountId, params, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
};
export default accountsService;
