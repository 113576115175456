import httpService from "../http.service";
import authorizationService from "../authorization.service";

const yearsService = {
  async getYears() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/year`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => console.log(error));
    return res;
  },
  async getYear(year) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/year/${year}`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => console.log(error));
    return res;
  },
  async getYearByYear(year) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
        .get(`/year/year/${year}`, authConfig)
        .then((response) => (res = response.data))
        .catch((error) => console.log(error));
    return res;
  },
  async createYear(year) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("Year", year.year);
    bodyFormData.append("MwSt", year.mwst);

    await httpService
      .post("/year", bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async updateYear(year) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("Year", year.year);
    bodyFormData.append("MwSt", year.mwst);
    await httpService
      .put(`/year/${year.yearId}`, bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },

  async deleteYear(yearId) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/year/" + yearId, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
};
export default yearsService;
