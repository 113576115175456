// @ is an alias to /src
import { mapMutations } from "vuex";
import authorizationService from "@/services/authorization.service.js";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      userName: null,
      password: null,
      wasWrong: false,
    };
  },
  async created() {},
  methods: {
    ...mapMutations(["SET_CURRENT_USER"]),
    async trylogin() {
      const token = await authorizationService.getToken(
        this.userName,
        this.password
      );
      if (!token) {
        this.userName = null;
        this.password = null;
        this.wasWrong = true;
        return;
      }
      this.SET_CURRENT_USER({
        userName: this.userName,
        password: this.password,
      });
      this.$router.push({ name: "Overview" });
    },
  },
};
