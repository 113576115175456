import httpService from "../http.service";
import authorizationService from "../authorization.service";

const addressbookService = {
  // Addresses
  async getAllAdresses() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/addressBook`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  async getAdresses(offset, limit) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/addressBook?offset=${offset}&limit=${limit}`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  // Organisation Contacts
  async getOrganisationContacts() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
        .get("/organisation-contacts", authConfig)
        .then((response) => (res = response.data))
        .catch((error) => {
          console.log(error);
          res = null;
        });
    return res;
  },
  // Contacts
  async getContacts() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get("/contact", authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  // Contact
  async getContact(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get(`/contact/${id}`, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  async getContactFiltered(
    organisationId,
    firstName,
    lastName,
    jobTitle,
    mail,
    phone,
    mobile,
    notes
  ) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("OrganisationId", organisationId);
    bodyFormData.append("FirstName", firstName);
    bodyFormData.append("LastName", lastName);
    bodyFormData.append("JobTitle", jobTitle);
    bodyFormData.append("MailAddress", mail);
    bodyFormData.append("BusinessPhone", phone);
    bodyFormData.append("MobilePhone", mobile);
    bodyFormData.append("Notes", notes);
    await httpService
      .post("/contact/filter", bodyFormData, authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  async createContact(contact) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("OrganisationId", contact.OrganisationId);
    bodyFormData.append("DepartmentId", contact.DepartmentId);
    bodyFormData.append("FirstName", contact.FirstName);
    bodyFormData.append("LastName", contact.LastName);
    bodyFormData.append("JobTitle", contact.JobTitle);
    bodyFormData.append("MailAddress", contact.MailAddress);
    bodyFormData.append("BusinessPhone", contact.BusinessPhone);
    bodyFormData.append("MobilePhone", contact.MobilePhone);
    bodyFormData.append("Notes", contact.Notes);

    await httpService
      .post("/contact", bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async updateContact(contact) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("OrganisationId", contact.OrganisationId);
    bodyFormData.append("DepartmentId", contact.DepartmentId);
    bodyFormData.append("FirstName", contact.FirstName);
    bodyFormData.append("LastName", contact.LastName);
    bodyFormData.append("JobTitle", contact.JobTitle);
    bodyFormData.append("MailAddress", contact.MailAddress);
    bodyFormData.append("BusinessPhone", contact.BusinessPhone);
    bodyFormData.append("MobilePhone", contact.MobilePhone);
    bodyFormData.append("Notes", contact.Notes);

    await httpService
      .put(`/contact/${contact.ContactId}`, bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async deleteContact(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/contact/" + id, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },

  //Departments
  async getDepartment(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
        .get(`/department/${id}`, authConfig)
        .then((response) => (res = response.data))
        .catch((error) => {
          console.log(error);
          res = null;
        });
    return res;
  },
  async getDepartments() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get("/department", authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  async getDepartmentsByOrganisationId(organisationId) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
        .get(`/department/organisation/${organisationId}`, authConfig)
        .then((response) => (res = response.data))
        .catch((error) => {
          console.log(error);
          res = null;
        });
    return res;
  },
  async createDepartment(department) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("DepartmentName", department.name);
    bodyFormData.append("OrganisationId", department.organisationId);
    bodyFormData.append("BusinessPhone", department.BusinessPhone);
    bodyFormData.append("FaxNumber", department.fax);
    bodyFormData.append("Street1", department.street);
    bodyFormData.append("StreetNumber", department.streetNumber);
    bodyFormData.append("Street2", department.street2);
    bodyFormData.append("Country", department.country);
    bodyFormData.append("City", department.city);
    bodyFormData.append("PostalCode", department.postalCode);

    await httpService
      .post("/department", bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async updateDepartment(departmentId, mainContact) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("MainContact", mainContact);

    await httpService
      .put(`/department/${departmentId}`, bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async updateDepartmentWithoutMainContact(department) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    let bodyFormData = new URLSearchParams();
    bodyFormData.append("DepartmentName", department.DepartmentName);
    bodyFormData.append("BusinessPhone", department.BusinessPhone);
    bodyFormData.append("FaxNumber", department.FaxNumber);
    bodyFormData.append("Street1", department.Street1);
    bodyFormData.append("StreetNumber", department.StreetNumber);
    bodyFormData.append("Street2", department.Street2);
    bodyFormData.append("City", department.City);
    bodyFormData.append("PostalCode", department.PostalCode);
    bodyFormData.append("Country", department.Country);
    bodyFormData.append("Notes", department.Notes);

    await httpService
        .put(`/department/${department.DepartmentId}`, bodyFormData, authConfig)
        .then((response) => {
          res = response.data;
        })
        .catch((error) => console.log(error));
    return res;
  },
  async deleteDepartment(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/department/" + id, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  //Organisations
  async getOrganisations() {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .get("/organisation", authConfig)
      .then((response) => (res = response.data))
      .catch((error) => {
        console.log(error);
        res = null;
      });
    return res;
  },
  //Organisation
  async getOrganisation(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
        .get(`/organisation/${id}`, authConfig)
        .then((response) => (res = response.data))
        .catch((error) => {
          console.log(error);
          res = null;
        });
    return res;
  },
  async createOrganisation(organisation) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("OrganisationName", organisation.name);
    bodyFormData.append("WebPage", organisation.webPage);
    bodyFormData.append("BusinessPhone", organisation.BusinessPhone);
    bodyFormData.append("FaxNumber", organisation.fax);
    bodyFormData.append("Street1", organisation.street);
    bodyFormData.append("StreetNumber", organisation.streetNumber);
    bodyFormData.append("Street2", organisation.street2);
    bodyFormData.append("Country", organisation.country);
    bodyFormData.append("City", organisation.city);
    bodyFormData.append("PostalCode", organisation.postalCode);

    await httpService
      .post("/organisation", bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async updateOrganisation(organisationId, mainContact) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("MainContact", mainContact);

    await httpService
      .put(`/organisation/${organisationId}`, bodyFormData, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
  async createOrganisationWithoutMainContact(organisation) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("OrganisationName", organisation.OrganisationName);
    bodyFormData.append("WebPage", organisation.WebPage);
    bodyFormData.append("BusinessPhone", organisation.BusinessPhone);
    bodyFormData.append("FaxNumber", organisation.FaxNumber);
    bodyFormData.append("Street1", organisation.Street1);
    bodyFormData.append("StreetNumber", organisation.StreetNumber);
    bodyFormData.append("Street2", organisation.Street2);
    bodyFormData.append("Country", organisation.Country);
    bodyFormData.append("City", organisation.City);
    bodyFormData.append("PostalCode", organisation.PostalCode);
    await httpService
        .post("/organisation", bodyFormData, authConfig)
        .then((response) => {
          res = response.data;
        })
        .catch((error) => console.log(error));
    return res;
  },
  async updateOrganisationWithoutMainContact(organisation) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    let bodyFormData = new URLSearchParams();
    bodyFormData.append("OrganisationName", organisation.OrganisationName);
    bodyFormData.append("BusinessPhone", organisation.BusinessPhone);
    bodyFormData.append("FaxNumber", organisation.FaxNumber);
    bodyFormData.append("Street1", organisation.Street1);
    bodyFormData.append("StreetNumber", organisation.StreetNumber);
    bodyFormData.append("Street2", organisation.Street2);
    bodyFormData.append("City", organisation.City);
    bodyFormData.append("PostalCode", organisation.PostalCode);
    bodyFormData.append("Country", organisation.Country);

    await httpService
        .put(`/organisation/${organisation.OrganisationId}`, bodyFormData, authConfig)
        .then((response) => {
          res = response.data;
        })
        .catch((error) => console.log(error));
    return res;
  },
  async deleteOrganisation(id) {
    let res;
    const authConfig = await authorizationService.getNewHeader();
    await httpService
      .delete("/organisation/" + id, authConfig)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => console.log(error));
    return res;
  },
};
export default addressbookService;
