import userService from "@/services/users/users.service";
import ProfileElement from "@/components/ProfileElement/ProfileElement.vue";
import CreatePopup from "@/components/CreatePopup/CreatePopup.vue";
import {mapGetters} from "vuex";

export default {
  name: "Profile",
  components: {
    CreatePopup,
    ProfileElement,
  },
  data() {
    return {
      profiles: [],
      createPopupIsOpen: false
    };
  },
  async created() {
    this.profiles = await userService.getUser(2);
  },
  mounted () {
    this.$root.$on('close_create_popup',() => {
      this.toggleCreatePopup();
    });
    this.$root.$on('close_create_popup_closest',() => {
      this.createPopupIsOpen = false;
    });
    this.$root.$on('refresh_entry', async () => {
      this.createPopupIsOpen = false;
      this.$emit("open_message_box", 'success');
    });
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),

    updateProfile() {
      console.log(this.getCurrentUser);
    },
    toggleCreatePopup() {
      this.createPopupIsOpen = !this.createPopupIsOpen;
    },
  },
};
