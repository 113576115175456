import Chart from 'chart.js'
import reportsService from "@/services/reports/reports.service";
import organisationService from "@/services/addressbook/addressbook.service";

export default {
    name: 'BarChart',
    props: {
        header: Array,
        filter: Object,
        allData: Boolean,
        costsJournalXAxis: Object,
    },
    data() {
        return {
            statisticData: [],
            organisations: [],
            showChart: {
                costs: true,
                revenues: false
            },
            graph: {
                waste: {},
                costs: {}
            },
            BarChart: {
                WasteAmount: {
                    type: "bar",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true,
                        lineTension: 1,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        padding: 25
                                    }
                                }
                            ]
                        },
                        plugins: {
                            datalabels: {
                                display: function (context) {
                                    return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                                }
                            }
                        }
                    }
                },
                Costs: {
                    type: "bar",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true,
                        lineTension: 1,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        padding: 25
                                    }
                                }
                            ]
                        },
                        plugins: {
                            datalabels: {
                                display: function (context) {
                                    return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    async created() {
        await this.createChart();
    },
    mounted() {
        this.$root.$on('update_statistics',async () => {
            this.emptyData();
            await this.updateChart();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
                this.emptyData();
                await this.updateChart();
            }
        },
        'filter.endDate': async function (value) {
            if (value !== '') {
                this.emptyData();
                await this.updateChart();
            }
        },
        'filter.accounts': async function () {
            this.emptyData();
            await this.updateChart();
        },
        'filter.organisations': async function () {
            this.emptyData();
            await this.updateChart();
        },
        'costsJournalXAxis.Dropdown': async function (value) {
            if (value === 'costs') {
                this.showChart.costs = !value;
                this.showChart.revenues = !!value;
            } else {
                this.showChart.costs = !!value;
                this.showChart.revenues = !value;
            }
            this.emptyData();
            await this.updateChart();
        }
    },
    methods: {
        randomRgba() {
            let o = Math.round, r = Math.random, s = 255;
            return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
        },
        emptyData() {
            this.statisticData.length = 0;
            this.organisations.length = 0;
            this.BarChart.WasteAmount.data.labels.length = 0;
            this.BarChart.WasteAmount.data.datasets.length = 0;
            this.BarChart.Costs.data.labels.length = 0;
            this.BarChart.Costs.data.datasets.length = 0;
        },
        async getJournalDataStatistic() {
            this.emptyData();
            if (this.costsJournalXAxis.Dropdown === 'costs') {
                this.statisticData = await reportsService.getReportCostsJournal(this.filter);
            } else {
                this.statisticData = await reportsService.getReportRevenuesJournal(this.filter);
            }
        },
        async getData() {
            await this.getJournalDataStatistic();

            this.BarChart.WasteAmount.data.labels = [];
            this.BarChart.WasteAmount.data.datasets = [];

            this.BarChart.Costs.data.labels = [];
            this.BarChart.Costs.data.datasets = [];

            for (let i = 0; i < this.statisticData.length; i++) {
                this.BarChart.WasteAmount.data.labels.push(this.statisticData[i].AccountName);
                this.BarChart.Costs.data.labels.push(this.statisticData[i].AccountName);
            }
            if (this.filter.organisations !== '') {
                let organisationResponse = await organisationService.getOrganisations();
                const organisationsArray = this.filter.organisations.split('_');
                for (let i = 0; i < organisationsArray.length; i++) {
                    for (let x = 0; x < organisationResponse.length; x++) {
                        if (parseInt(organisationsArray[i]) === parseInt(organisationResponse[x].OrganisationId)) {
                            this.organisations.push({
                                'OrganisationId': organisationsArray[i],
                                'OrganisationName': organisationResponse[x].OrganisationName
                            });
                        }
                    }
                }
            } else {
                this.organisations = await organisationService.getOrganisations();
            }
            for (let i = 0; i < this.organisations.length; i++) {
                const randomColor = this.randomRgba();
                const obj1 = {
                    label: this.organisations[i].OrganisationName,
                    type: "bar",
                    data: [],
                    backgroundColor: randomColor,
                    borderColor: randomColor,
                    borderWidth: 3
                };
                for (let x = 0; x < this.statisticData.length; x++) { //4 soviel wie es accounts hat
                    let sum = 0;
                    for (let z = 0; z < this.statisticData[x].organisations.length; z++) { //soviel wie es orgis hat
                        if (parseInt(this.statisticData[x].organisations[z].OrganisationId) === parseInt(this.organisations[i].OrganisationId)) {
                            sum = sum + this.statisticData[x].organisations[z].Weight;
                        }
                    }
                    obj1.data.push(sum);
                }
                const obj2 = {
                    label: this.organisations[i].OrganisationName,
                    type: "bar",
                    data: [],
                    backgroundColor: randomColor,
                    borderColor: randomColor,
                    borderWidth: 3
                };
                for (let x = 0; x < this.statisticData.length; x++) {
                    let sum = 0;
                    for (let z = 0; z < this.statisticData[x].organisations.length; z++) {
                        if (parseInt(this.statisticData[x].organisations[z].OrganisationId) === parseInt(this.organisations[i].OrganisationId)) {
                            if (this.costsJournalXAxis.Dropdown === 'costs') {
                                sum = sum + this.statisticData[x].organisations[z].CostsTotal;
                            } else {
                                sum = sum + this.statisticData[x].organisations[z].RevenuesTotal;
                            }
                        }
                    }
                    obj2.data.push(sum);
                }
                this.BarChart.WasteAmount.data.datasets.push(obj1);
                this.BarChart.Costs.data.datasets.push(obj2);
            }
        },
        async createChart() {
            await this.getData();
            const ctx1 = document.querySelector('.bar-chart-waste-amount');
            const ctx2 = document.querySelector('.bar-chart-costs');
            this.graph.waste = new Chart(ctx1, this.BarChart.WasteAmount);
            this.graph.costs = new Chart(ctx2, this.BarChart.Costs);
        },
        async updateChart() {
            await this.getData();
            this.graph.waste.update(this.BarChart.WasteAmount);
            this.graph.costs.update(this.BarChart.Costs);
        }
    }
}