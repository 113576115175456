import Chart from 'chart.js'
import reportsService from "@/services/reports/reports.service";

export default {
    name: 'LineChart',
    props: {
        header: Array,
        showTotals: true,
        totalBalanceXAxis: Object,
        filter: Object,
        allData: true
    },
    data() {
        return {
            statisticData: {
                AccountNr: 0,
                AccountName: 0,
                WasteAmountKg: 0,
                WasteAmountPercent: 0,
                CostsFr: 0,
                CostsPercent: 0,
                CostsFrT: 0,
                RevenuesFr: 0,
                RevenuesPercent: 0,
                RevenuesFrT: 0,
                CostRecoveryFr: 0
            },
            graph: {},
            WasteAmountDataPlaceholer: {
                label: "Abfallmenge",
                data: [],
                borderColor: '#f5f9e9',
                fill: false,
                spanGaps: true
            },
            CostsDataPlaceholder: {
                label: "Kosten",
                data: [],
                borderColor: '#dc3545',
                fill: false,
                spanGaps: true
            },
            RevenuesDataPlaceholder: {
                label: "Erlöse",
                data: [],
                borderColor: '#a2c309',
                fill: false,
                spanGaps: true
            },
            LineChart: {
                type: "line",
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                }
            }
        }
    },
    async created() {
        this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        this.createChart();
    },
    mounted() {
        this.$root.$on('update_statistics',async () => {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
            }
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.accounts': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.startDate': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.endDate': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.WasteAmount': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.Costs': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.Revenues': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
    },
    methods: {
        getData() {
            this.LineChart.data.labels = [];
            this.LineChart.data.datasets = [];
            this.WasteAmountDataPlaceholer.data = [];
            this.CostsDataPlaceholder.data = [];
            this.RevenuesDataPlaceholder.data = [];
            for (let i = 0; i < this.statisticData.length; i++) {
                this.LineChart.data.labels.push(this.statisticData[i].AccountName);
                this.WasteAmountDataPlaceholer.data.push(this.statisticData[i].WasteAmountKg);
                this.CostsDataPlaceholder.data.push(this.statisticData[i].CostsFr);
                this.RevenuesDataPlaceholder.data.push(this.statisticData[i].RevenuesFr);
            }
            if (this.totalBalanceXAxis.WasteAmount) {
                this.LineChart.data.datasets.push(this.WasteAmountDataPlaceholer);
            }
            if (this.totalBalanceXAxis.Costs) {
                this.LineChart.data.datasets.push(this.CostsDataPlaceholder);
            }
            if (this.totalBalanceXAxis.Revenues) {
                this.LineChart.data.datasets.push(this.RevenuesDataPlaceholder);
            }
        },
        createChart() {
            this.getData();
            const ctx = document.querySelector('.line-chart');
            this.graph = new Chart(ctx, this.LineChart);
        },
        updateChart() {
            this.getData();
            this.graph.update(this.LineChart);
        }
    }
}