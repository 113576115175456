import Chart from 'chart.js'
import reportsService from "@/services/reports/reports.service";

export default {
    name: 'PieChart',
    props: {
        header: Array,
        filter: Object,
        allData: Boolean,
        costsJournalXAxis: Object,
    },
    data() {
        return {
            statisticData: [],
            organisations: [],
            organisationTotal: [],
            showChart: {
                costs: true,
                revenues: false
            },
            graph: {
                waste: {},
                costs: {}
            },
            WasteAmountDataPlaceholer: {
                label: [],
                data: [],
                backgroundColor: [],
                hoverOffset: 4
            },
            CostsDataPlaceholder: {
                label: [],
                data: [],
                backgroundColor: [],
                hoverOffset: 4
            },
            PieChart: {
                WasteAmount: {
                    type: "pie",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true
                    }
                },
                Costs: {
                    type: "pie",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true
                    }
                }
            }
        }
    },
    async created() {
        await this.createChart();
    },
    mounted() {
        this.$root.$on('update_statistics',async () => {
            this.emptyData();
            await this.updateChart();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
                this.emptyData();
                await this.updateChart();
            }
        },
        'filter.endDate': async function (value) {
            if (value !== '') {
                this.emptyData();
                await this.updateChart();
            }
        },
        'filter.accounts': async function () {
            this.emptyData();
            await this.updateChart();
        },
        'filter.organisations': async function () {
            this.emptyData();
            await this.updateChart();
        },
        'costsJournalXAxis.Dropdown': async function (value) {
            if (value === 'costs') {
                this.showChart.costs = !value;
                this.showChart.revenues = !!value;
            } else {
                this.showChart.costs = !!value;
                this.showChart.revenues = !value;
            }
            this.emptyData();
            await this.updateChart();
        },
    },
    methods: {
        randomRgba() {
            let o = Math.round, r = Math.random, s = 255;
            return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
        },
        emptyData() {
            this.statisticData.length = 0;
            this.organisations.length = 0;
            this.PieChart.WasteAmount.data.labels.length = 0;
            this.PieChart.WasteAmount.data.datasets.length = 0;
            this.PieChart.Costs.data.labels.length = 0;
            this.PieChart.Costs.data.datasets.length = 0;
        },
        async getJournalDataStatistic() {
            this.emptyData();
            if (this.costsJournalXAxis.Dropdown === 'costs') {
                this.statisticData = await reportsService.getReportCostsJournal(this.filter);
            } else {
                this.statisticData = await reportsService.getReportRevenuesJournal(this.filter);
            }
        },
        async getData() {
            await this.getJournalDataStatistic();

            this.PieChart.WasteAmount.data.labels = [];
            this.PieChart.WasteAmount.data.datasets = [];
            this.PieChart.Costs.data.labels = [];
            this.PieChart.Costs.data.datasets = [];
            this.organisations = await reportsService.getReportOrganisationTotalJournal(this.filter);

            for (let i = 0; i < this.organisations.length; i++) {
                const randomColor = this.randomRgba();
                this.PieChart.WasteAmount.data.labels.push(this.organisations[i].OrganisationName);
                this.PieChart.Costs.data.labels.push(this.organisations[i].OrganisationName);

                this.WasteAmountDataPlaceholer.backgroundColor.push(randomColor);
                this.CostsDataPlaceholder.backgroundColor.push(randomColor);

                this.WasteAmountDataPlaceholer.data.push(this.organisations[i].WeightTotal);
                this.CostsDataPlaceholder.data.push(this.organisations[i].CostsTotal);
            }

            this.PieChart.WasteAmount.data.datasets.push(this.WasteAmountDataPlaceholer);
            this.PieChart.Costs.data.datasets.push(this.CostsDataPlaceholder);
        },
        async createChart() {
            await this.getData();
            const ctx1 = document.querySelector('.pie-chart-waste-amount');
            const ctx2 = document.querySelector('.pie-chart-costs');
            this.graph.waste = new Chart(ctx1, this.PieChart.WasteAmount);
            this.graph.costs = new Chart(ctx2, this.PieChart.Costs);
        },
        async updateChart() {
            await this.getData();
            this.graph.waste.update(this.PieChart.WasteAmount);
            this.graph.costs.update(this.PieChart.Costs);
        }
    },
}
