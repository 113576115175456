import accountsService from "@/services/accounts/accounts.service";

// @ is an alias to /src
export default {
  name: "CreateAccount",
  components: {},
  data() {
    return {
      newAccount: {
        accountNr: null,
        accountName: null,
        accountClass: null,
        financeAccount: null,
      },
      updateAccount: {
        accountId: null,
        accountNr: null,
        accountName: null,
        accountClass: null,
        financeAccount: null,
      },
      isComplete: true,
      accounts: {},
    };
  },
  async created() {
    this.accounts = await accountsService.getAccounts();
    this.resetUpdateAccount();
  },
  methods: {
    async submit() {
      if (
        !this.newAccount.accountNr ||
        !this.newAccount.accountName ||
        !this.newAccount.accountClass ||
        !this.newAccount.financeAccount
      ) {
        this.isComplete = false;
        return;
      }
      this.isComplete = true;
      await accountsService.createAccount(this.newAccount);
      this.accounts = await accountsService.getAccounts();
      this.newAccount.accountNr = null;
      this.newAccount.accountName = null;
      this.newAccount.accountClass = null;
      this.newAccount.financeAccount = null;
      this.$root.$emit("refresh_entry");
    },
    async deleteAccount(id) {
      await accountsService.deleteAccount(id);
      this.accounts = await accountsService.getAccounts();
      this.$root.$emit("delete_entry");
    },
    async update() {
      await accountsService.updateAccount(this.updateAccount);
      this.resetUpdateAccount();
      this.accounts = await accountsService.getAccounts();
      this.$root.$emit("refresh_entry");
    },
    resetUpdateAccount() {
      this.updateAccount.accountId = null;
      this.updateAccount.accountNr = null;
      this.updateAccount.accountName = null;
      this.updateAccount.accountClass = null;
      this.updateAccount.financeAccount = null;
    },
    chooseAccountToUpdate(acc) {
      this.updateAccount.accountId = acc.AccountId;
      this.updateAccount.accountNr = acc.AccountNr;
      this.updateAccount.accountName = acc.AccountName;
      this.updateAccount.accountClass = acc.AccountClass;
      this.updateAccount.financeAccount = acc.FinanceAccount;
    },
  },
};
