import addressbookService from "@/services/addressbook/addressbook.service";

export default {
    name: "CreateAddress",
    components: {},
    props: {
        addressToUpdate: Object,
    },
    data() {
        return {
            contact: {
                OrganisationId: null,
                DepartmentId: null,
                FirstName: null,
                LastName: null,
                JobTitle: null,
                MailAddress: null,
                BusinessPhone: null,
                MobilePhone: null,
                Notes: null,
            },

            organisation: {
                Name: null,
                webPage: null,
                BusinessPhone: null,
                fax: null,
                street: null,
                streetNumber: null,
                street2: null,
                city: null,
                postalCode: null,
                country: null,
            },

            department: {
                OrganisationId: null,
                name: null,
                BusinessPhone: null,
                fax: null,
                street: null,
                streetNumber: null,
                street2: null,
                country: null,
                city: null,
                postalCode: null,
            },

            isMainContactOrganisation: false,
            isMainContactDepartment: false,
            addresses: {},
            organisationIsValid: false,
            departmentIsValid: false,
            organisations: {},
            allDepartments: [],
            currentStep: "firstStep",
            step: 1,
            isComplete: true,
        };
    },
    watch: {
        "contact.OrganisationId": function () {
            if (this.addressToUpdate && this.addressToUpdate.OrganisationId !== this.contact.OrganisationId)
                this.contact.DepartmentId = null;
        },
    },
    computed: {
        departments: function () {
            var departments = [];
            for (let i = 0; i < this.allDepartments.length; i++) {
                const e = this.allDepartments[i];
                if (e.OrganisationId == this.contact.OrganisationId) {
                    departments.push(e);
                }
            }
            return departments;
        },
    },
    async created() {
        this.addresses = await addressbookService.getAllAdresses();
        this.organisations = await addressbookService.getOrganisations();
        this.allDepartments = await addressbookService.getDepartments();
        if (this.addressToUpdate){
            this.contact = this.addressToUpdate;
            this.checkOrganisationsMainContact(this.organisations, this.addressToUpdate.ContactId);
            this.checkDepartmentsMainContact(this.allDepartments, this.addressToUpdate.ContactId);
        }
    },
    methods: {
        checkOrganisationsMainContact(organisations, contactId) {
            for (let i = 0; i < organisations.length; i++) {
                const organisation = organisations[i];
                if (organisation.MainContact === contactId)
                    this.isMainContactOrganisation = true;
            }
        },
        checkDepartmentsMainContact(departments, contactId) {
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.MainContact === contactId)
                    this.isMainContactDepartment = true;
            }
        },
        checkOrganisationHasDepartment(organisationId){
            let checker = false;
            for (let i = 0; i < this.allDepartments.length; i++) {
                const department = this.allDepartments[i];
                if (department.OrganisationId === organisationId)
                    checker = true;
            }
            return checker;
        },
        async submitStep1() {
            if (!this.contact.FirstName || !this.contact.LastName) {
                this.isComplete = false;
                return;
            }
            this.isComplete = true;
            ++this.step;
        },
        async submitStep2() {
            if (!this.contact.OrganisationId) {
                this.isComplete = false;
                return;
            }
            this.isComplete = true;
            ++this.step;
        },

        async submit() {
            let newContact = await addressbookService.createContact(this.contact);

            if (this.isMainContactOrganisation) {
                await addressbookService.updateOrganisation(
                    this.contact.OrganisationId,
                    newContact.ContactId
                );
            }
            if (this.isMainContactDepartment && this.contact.DepartmentId) {
                await addressbookService.updateDepartment(
                    this.contact.DepartmentId,
                    newContact.ContactId
                );
            }
            this.step = 1;
            this.contact.OrganisationId = null;
            this.contact.DepartmentId = null;
            this.contact.FirstName = null;
            this.contact.LastName = null;
            this.contact.JobTitle = null;
            this.contact.MailAddress = null;
            this.contact.BusinessPhone = null;
            this.contact.MobilePhone = null;
            this.contact.Notes = null;
            this.departmentIsNotSelected = false;
            this.currentStep = "firstStep";
            this.$root.$emit("close_create_popup");
            this.$root.$emit("refresh_entry");
        },
        async update() {
            if(!this.checkOrganisationHasDepartment(this.contact.OrganisationId))
                this.contact.DepartmentId = null;

            await addressbookService.updateContact(this.contact);

            if (this.isMainContactOrganisation) {
                await addressbookService.updateOrganisation(
                    this.contact.OrganisationId,
                    this.addressToUpdate.ContactId
                );
            } else {
                await addressbookService.updateOrganisation(
                    this.contact.OrganisationId,
                    null
                );
            }
            if (this.isMainContactDepartment && this.contact.DepartmentId) {
                await addressbookService.updateDepartment(
                    this.contact.DepartmentId,
                    this.addressToUpdate.ContactId
                );
            } else if(!this.isMainContactDepartment && this.contact.DepartmentId) {
                await addressbookService.updateDepartment(
                    this.contact.DepartmentId,
                    null
                );
            }
            this.$emit("close_update_popup", true);
            this.$root.$emit("refresh_entry");
        },
        async createOrganisation() {
            if (!this.organisation.name) {
                this.organisationIsValid = true;
                return;
            }
            await addressbookService.createOrganisation(this.organisation);
            this.organisation.name = null;
            this.organisation.webPage = null;
            this.organisation.BusinessPhone = null;
            this.organisation.fax = null;
            this.organisation.street = null;
            this.organisation.streetNumber = null;
            this.organisation.street2 = null;
            this.organisation.city = null;
            this.organisation.postalCode = null;
            this.organisation.country = null;

            this.organisationIsValid = false;
            this.organisations = await addressbookService.getOrganisations();
            this.step = 2;
            this.$root.$emit("refresh_entry");
        },

        async createDepartment() {
            if (!this.department.name) {
                this.departmentIsValid = true;
                return;
            }
            this.department.organisationId = this.contact.OrganisationId;
            await addressbookService.createDepartment(this.department);

            this.department.name = null;
            this.department.organisationId = null;
            this.department.BusinessPhone = null;
            this.department.fax = null;
            this.department.street = null;
            this.department.streetNumber = null;
            this.department.street2 = null;
            this.department.country = null;
            this.department.city = null;
            this.department.postalCode = null;

            this.departmentIsValid = false;
            this.allDepartments = await addressbookService.getDepartments();
            this.step = 3;
            this.$root.$emit("refresh_entry");
        },
        async deleteDepartment(id) {
            try {
                await addressbookService.deleteDepartment(id);
                this.allDepartments = await addressbookService.getDepartments();
                this.$root.$emit("delete_entry");
            } catch (e) {
                console.log(e);
            }
        },
        async deleteOrganisation(id) {
            try {
                await addressbookService.deleteOrganisation(id);
                this.organisations = await addressbookService.getOrganisations();
                this.$root.$emit("delete_entry");
            } catch (e) {
                console.log(e);
            }
        },
    },
};
