// @ is an alias to /src
import reportsService from "@/services/reports/reports.service";
import Chart from "@/components/Chart/Chart.vue";
import accountsService from "@/services/accounts/accounts.service";
import organisationService from "@/services/addressbook/addressbook.service";
import TotalBalance from "@/components/StatisticTables/TotalBalance/TotalBalance.vue";
import TimeComparison from "@/components/StatisticTables/TimeComparison/TimeComparison.vue";
import CostJournal from "@/components/StatisticTables/CostJournal/CostJournal.vue";
import Multiselect from 'vue-multiselect';

export default {
    name: "Statistic",
    components: {
        TotalBalance,
        TimeComparison,
        CostJournal,
        Chart,
        Multiselect
    },
    data() {
        return {
            templates: [],
            accounts: [],
            organisations: [],
            reportTemplate: {
                ReportName: null,
                ReportCategory: null,
                StartDate: null,
                EndDate: null,
                Accounts: null,
                Organisations: null,
                TotalBalanceXAxisWasteAmount: true,
                TotalBalanceXAxisWasteAmountKg: true,
                TotalBalanceXAxisWasteAmountPercent: true,
                TotalBalanceXAxisCosts: true,
                TotalBalanceXAxisCostsFr: true,
                TotalBalanceXAxisCostsPercent: true,
                TotalBalanceXAxisCostsFrT: true,
                TotalBalanceXAxisRevenues: true,
                TotalBalanceXAxisRevenuesFr: true,
                TotalBalanceXAxisRevenuesPercent: true,
                TotalBalanceXAxisRevenuesFrT: true,
                TotalBalanceXAxisCostRecovery: true,
                CostsJournalXAxisDropdown: 'costs',
                CostsJournalXAxisWasteAmountKg: true,
                CostsJournalXAxisCosts: true,
                CostsJournalXAxisCostsMwSt: true,
                CostsJournalXAxisCostsTotal: true,
                TimeComparisonXAxisDropdown: 'amount',
                TimeComparisonXAxisPerMonthXAxis: true,
                TimeComparisonXAxisPerYearXAxis: false,
                TimeComparisonXAxisTotalXAxis: true
            },
            showTotals: true,
            reportData: null,
            yDropdown: null,
            xDropdown: "costs",
            statisticType: "totalBalance",
            statisticTypes: {
                totalBalance: true,
                costJournal: false,
                timeComparison: false
            },
            tableTitle: "Übersicht",
            totalBalanceXAxis: {
                WasteAmount: true,
                WasteAmountKg: true,
                WasteAmountPercent: true,
                Costs: true,
                CostsFr: true,
                CostsPercent: true,
                CostsFrT: true,
                Revenues: true,
                RevenuesFr: true,
                RevenuesPercent: true,
                RevenuesFrT: true,
                CostRecovery: true
            },
            costsJournalXAxis: {
                Dropdown: 'costs',
                WasteAmountKg: true,
                Costs: true,
                CostsMwSt: true,
                CostsTotal: true
            },
            timeComparisonXAxis: {
                Dropdown: 'amount',
                PerMonthXAxis: true,
                PerYearXAxis: false,
                TotalXAxis: true
            },
            showNameField: false,
            newTemplate: true,
            chartDataFinal: {},
            allData: true,
            chartData: {
                Costs: {
                    Account: true,
                    Total: false,
                    Average: false,
                    PerHead: false,
                    PerMonth: false,
                },
                Amount: {
                    Percent: false,
                },
            },
            showData: true,
            filter: {
                accounts: '',
                organisations: '',
                startDate: '',
                endDate: ''
            },
            showDownloadPopup: false,
            downloadUrl: null,
            selectedAccounts: [],
            selectedOrganisations: []
        };
    },
    computed: {},
    watch: {
        selectedAccounts: async function (value) {
            this.filter.accounts = '';
            for (let i = 0; i < value.length; i++) {
                if (i === (value.length - 1)) {
                    this.filter.accounts += value[i].AccountId;
                } else {
                    this.filter.accounts += value[i].AccountId + '_';
                }
            }
        },
        selectedOrganisations: async function (value) {
            this.filter.organisations = '';
            for (let i = 0; i < value.length; i++) {
                if (i === (value.length - 1)) {
                    this.filter.organisations += value[i].OrganisationId;
                } else {
                    this.filter.organisations += value[i].OrganisationId + '_';
                }
            }
        },
        allData: function (value) {
            if (value) {
                this.reportTemplate.StartDate = null;
                this.reportTemplate.EndDate = null;
            }
        },
        chartData: {
            handler: function () {
                if (this.xDropdown === "costs") {
                    this.chartDataFinal = this.chartData.Costs;
                } else if (this.xDropdown === "amount") {
                    this.chartDataFinal = this.chartData.Amount;
                }
            },
            deep: true,
        },
        xDropdown(n) {
            this.reportTemplate.KostenXAxis = n === "costs";
            if (!this.reportTemplate.KostenXAxis) {
                this.reportTemplate.KgXAxis = false;
                this.reportTemplate.VolumeXAxis = false;
                this.reportTemplate.AmountXAxis = false;
            }
        },
        async statisticType(value) {
            this.resetDataAfterChangeStatisticType();
            for (const key in this.statisticTypes) {
                key === value ? this.statisticTypes[key] = true : this.statisticTypes[key] = false;
            }
            await this.showTemplatesPerCategory();
            if (this.statisticTypes.totalBalance) {
                this.tableTitle = 'Übersicht';
                this.reportTemplate.ReportCategory = 'totalBalance';
            }
            if (this.statisticTypes.costJournal) {
                this.tableTitle = 'Journal';
                this.reportTemplate.ReportCategory = 'costJournal';
            }
            if (this.statisticTypes.timeComparison) {
                this.tableTitle = 'Zeitvergleich';
                this.reportTemplate.ReportCategory = 'timeComparison';
            }
        },
        reportTemplate: {
            handler: function () {
                if (this.reportTemplate.StartDate && this.reportTemplate.EndDate && this.reportTemplate.StartDate !== '0000-00-00' && this.reportTemplate.EndDate !== '0000-00-00') {
                    this.filter.startDate = this.reportTemplate.StartDate;
                    this.filter.endDate = this.reportTemplate.EndDate;
                }
            },
            deep: true,
        },
    },
    async created() {
        this.updateFiltersTemplate();
        await this.showTemplatesPerCategory();
        this.accounts = await accountsService.getAccounts();
        this.organisations = await organisationService.getOrganisations();
    },
    mounted() {
        this.$root.$on('close_create_popup', () => {
            this.$root.$emit('close_create_popup_closest');
        });
        this.$root.$on('refresh_entry', async () => {
            this.$emit("open_message_box", 'success');
        });
        this.$root.$on('delete_entry', () => {
            this.$emit("open_message_box", 'danger');
        });
        this.$root.$on('delete_entry_template', async (id) => {
            await reportsService.deleteTemplate(id);
            await this.showTemplatesPerCategory();
            this.resetDataAfterChangeStatisticType();
            this.$emit("open_message_box", 'danger');
        });
    },
    methods: {
        changeAllData() {
            this.allData = false;
        },
        async showTemplatesPerCategory() {
            this.templates = [];
            let templates = await reportsService.getTemplates();
            for (let i = 0; i < templates.length; i++) {
                if (this.statisticType === templates[i].ReportCategory) {
                    this.templates.push(templates[i]);
                }
            }
        },
        resetDataAfterChangeStatisticType() {
            this.reportTemplate.ReportId = null
            this.reportTemplate.ReportCategory = null
            this.reportTemplate.StartDate = null;
            this.reportTemplate.EndDate = null;
            this.reportTemplate.Accounts = '';
            this.reportTemplate.Organisations = '';
            this.reportTemplate.TotalBalanceXAxisWasteAmount = true;
            this.reportTemplate.TotalBalanceXAxisWasteAmountKg = true;
            this.reportTemplate.TotalBalanceXAxisWasteAmountPercent = true;
            this.reportTemplate.TotalBalanceXAxisCosts = true;
            this.reportTemplate.TotalBalanceXAxisCostsFr = true;
            this.reportTemplate.TotalBalanceXAxisCostsPercent = true;
            this.reportTemplate.TotalBalanceXAxisCostsFrT = true;
            this.reportTemplate.TotalBalanceXAxisRevenues = true;
            this.reportTemplate.TotalBalanceXAxisRevenuesFr = true;
            this.reportTemplate.TotalBalanceXAxisRevenuesPercent = true;
            this.reportTemplate.TotalBalanceXAxisRevenuesFrT = true;
            this.reportTemplate.TotalBalanceXAxisCostRecovery = true;
            this.reportTemplate.CostsJournalXAxisDropdown = 'costs';
            this.reportTemplate.CostsJournalXAxisWasteAmountKg = true;
            this.reportTemplate.CostsJournalXAxisCosts = true;
            this.reportTemplate.CostsJournalXAxisCostsMwSt = true;
            this.reportTemplate.CostsJournalXAxisCostsTotal = true;
            this.reportTemplate.TimeComparisonXAxisDropdown = 'amount';
            this.reportTemplate.TimeComparisonXAxisPerMonthXAxis = true;
            this.reportTemplate.TimeComparisonXAxisPerYearXAxis = false;
            this.reportTemplate.TimeComparisonXAxisTotalXAxis = true;
            this.selectedAccounts = [];
            this.selectedOrganisations = [];
            this.filter.accounts = '';
            this.filter.startDate = '';
            this.filter.endDate = '';
            this.filter.organisations = '';
        },
        updateFiltersTemplate() {
            this.reportTemplate.ReportCategory = this.statisticType;
            this.reportTemplate.StartDate = this.filter.startDate;
            this.reportTemplate.EndDate = this.filter.endDate;
            this.reportTemplate.Accounts = this.filter.accounts;
            this.reportTemplate.Organisations = this.filter.organisations;
            this.totalBalanceXAxis.WasteAmount ? this.reportTemplate.TotalBalanceXAxisWasteAmount = 1 : this.reportTemplate.TotalBalanceXAxisWasteAmount = 0;
            this.totalBalanceXAxis.WasteAmountKg ? this.reportTemplate.TotalBalanceXAxisWasteAmountKg = 1 : this.reportTemplate.TotalBalanceXAxisWasteAmountKg = 0;
            this.totalBalanceXAxis.WasteAmountPercent ? this.reportTemplate.TotalBalanceXAxisWasteAmountPercent = 1 : this.reportTemplate.TotalBalanceXAxisWasteAmountKg = 0;
            this.totalBalanceXAxis.Costs ? this.reportTemplate.TotalBalanceXAxisCosts = 1 : this.reportTemplate.TotalBalanceXAxisCosts = 0;
            this.totalBalanceXAxis.CostsFr ? this.reportTemplate.TotalBalanceXAxisCostsFr = 1 : this.reportTemplate.TotalBalanceXAxisCostsFr = 0;
            this.totalBalanceXAxis.CostsPercent ? this.reportTemplate.TotalBalanceXAxisCostsPercent = 1 : this.reportTemplate.TotalBalanceXAxisCostsPercent = 0;
            this.totalBalanceXAxis.CostsFrT ? this.reportTemplate.TotalBalanceXAxisCostsFrT = 1 : this.reportTemplate.TotalBalanceXAxisCostsFrT = 0;
            this.totalBalanceXAxis.Revenues ? this.reportTemplate.TotalBalanceXAxisRevenues = 1 : this.reportTemplate.TotalBalanceXAxisRevenues = 0;
            this.totalBalanceXAxis.RevenuesFr ? this.reportTemplate.TotalBalanceXAxisRevenuesFr = 1 : this.reportTemplate.TotalBalanceXAxisRevenuesFr = 0;
            this.totalBalanceXAxis.RevenuesPercent ? this.reportTemplate.TotalBalanceXAxisRevenuesPercent = 1 : this.reportTemplate.TotalBalanceXAxisRevenuesPercent = 0;
            this.totalBalanceXAxis.RevenuesFrT ? this.reportTemplate.TotalBalanceXAxisRevenuesFrT = 1 : this.reportTemplate.TotalBalanceXAxisRevenuesFrT = 0;
            this.totalBalanceXAxis.CostRecovery ? this.reportTemplate.TotalBalanceXAxisCostRecovery = 1 : this.reportTemplate.TotalBalanceXAxisCostRecovery = 0;
            this.reportTemplate.CostsJournalXAxisDropdown = this.costsJournalXAxis.Dropdown;
            this.costsJournalXAxis.WasteAmountKg ? this.reportTemplate.CostsJournalXAxisWasteAmountKg = 1 : this.reportTemplate.CostsJournalXAxisWasteAmountKg = 0;
            this.costsJournalXAxis.Costs ? this.reportTemplate.CostsJournalXAxisCosts = 1 : this.reportTemplate.CostsJournalXAxisCosts = 0;
            this.costsJournalXAxis.CostsMwSt ? this.reportTemplate.CostsJournalXAxisCostsMwSt = 1 : this.reportTemplate.CostsJournalXAxisCostsMwSt = 0;
            this.costsJournalXAxis.CostsTotal ? this.reportTemplate.CostsJournalXAxisCostsTotal = 1 : this.reportTemplate.CostsJournalXAxisCostsTotal = 0;
            this.reportTemplate.TimeComparisonXAxisDropdown = this.timeComparisonXAxis.Dropdown;
            this.timeComparisonXAxis.PerMonthXAxis ? this.reportTemplate.TimeComparisonXAxisPerMonthXAxis = 1 : this.reportTemplate.TimeComparisonXAxisPerMonthXAxis = 0;
            this.timeComparisonXAxis.PerYearXAxis ? this.reportTemplate.TimeComparisonXAxisPerYearXAxis = 1 : this.reportTemplate.TimeComparisonXAxisPerYearXAxis = 0;
            this.timeComparisonXAxis.TotalXAxis ? this.reportTemplate.TimeComparisonXAxisTotalXAxis = 1 : this.reportTemplate.TimeComparisonXAxisTotalXAxis = 0;
        },
        async saveTemplate() {
            this.reportTemplate.SaveAsTemplate = true;
            this.updateFiltersTemplate();
            await reportsService.createReport(this.reportTemplate);
            this.reportTemplate.SaveAsTemplate = false;
            await this.showTemplatesPerCategory();
            this.showNameField = false;
            this.$emit("open_message_box", 'success');
        },
        async exportExcel() {
            this.downloadUrl = await reportsService.excelExport(this.reportTemplate, this.filter,this.totalBalanceXAxis,this.costsJournalXAxis,this.timeComparisonXAxis);
            this.showDownloadPopup = true;
        },
        async updateTemplate() {
            this.updateFiltersTemplate();
            await reportsService.updateTemplate(this.reportTemplate);
            await this.showTemplatesPerCategory();
            this.showNameField = false;
            this.$emit("open_message_box", 'success');
        },
        async deleteTemplate() {
            this.$emit("open_delete_popup", this.reportTemplate.ReportId, 'template');
        },
        setReport(t) {
            this.reportTemplate = t;

            this.tableTitle = this.reportTemplate.ReportName;


            this.filter.accounts = this.reportTemplate.Accounts;

            if (!this.reportTemplate.StartDate || this.reportTemplate.StartDate === '0000-00-00') {
                this.reportTemplate.StartDate = null;
                this.filter.startDate = '';
                this.allData = true;
            } else {
                this.allData = false;
            }

            if (!this.reportTemplate.EndDate || this.reportTemplate.EndDate === '0000-00-00') {
                this.reportTemplate.EndDate = null;
                this.filter.endDate = '';
                this.allData = true;
            } else {
                this.allData = false;
            }

            if (this.reportTemplate.Accounts) {
                this.selectedAccounts = [];
                const selectedAccounts = this.reportTemplate.Accounts.split('_');
                for (let x = 0; x < selectedAccounts.length; x++) {
                    for (let i = 0; i < this.accounts.length; i++) {
                        if (this.accounts[i].AccountId === parseInt(selectedAccounts[x])) {
                            this.selectedAccounts.push(this.accounts[i])
                        }
                    }
                }
            } else {
                this.selectedAccounts = [];
                this.filter.accounts = '';
            }
            if (this.reportTemplate.Organisations) {
                this.selectedOrganisations = [];
                const selectedOrganisations = this.reportTemplate.Organisations.split('_');
                for (let x = 0; x < selectedOrganisations.length; x++) {
                    for (let i = 0; i < this.organisations.length; i++) {
                        if (this.organisations[i].OrganisationId === parseInt(selectedOrganisations[x])) {
                            this.selectedOrganisations.push(this.organisations[i])
                        }
                    }
                }
            } else {
                this.selectedOrganisations = [];
                this.filter.organisations = '';
            }

            this.totalBalanceXAxis.WasteAmount = this.reportTemplate.TotalBalanceXAxisWasteAmount;
            this.totalBalanceXAxis.WasteAmountKg = this.reportTemplate.TotalBalanceXAxisWasteAmountKg;
            this.totalBalanceXAxis.WasteAmountPercent = this.reportTemplate.TotalBalanceXAxisWasteAmountPercent;
            this.totalBalanceXAxis.Costs = this.reportTemplate.TotalBalanceXAxisCosts;
            this.totalBalanceXAxis.CostsFr = this.reportTemplate.TotalBalanceXAxisCostsFr;
            this.totalBalanceXAxis.CostsPercent = this.reportTemplate.TotalBalanceXAxisCostsPercent;
            this.totalBalanceXAxis.CostsFrT = this.reportTemplate.TotalBalanceXAxisCostsFrT;
            this.totalBalanceXAxis.Revenues = this.reportTemplate.TotalBalanceXAxisRevenues;
            this.totalBalanceXAxis.RevenuesFr = this.reportTemplate.TotalBalanceXAxisRevenuesFr;
            this.totalBalanceXAxis.RevenuesPercent = this.reportTemplate.TotalBalanceXAxisRevenuesPercent;
            this.totalBalanceXAxis.RevenuesFrT = this.reportTemplate.TotalBalanceXAxisRevenuesFrT;
            this.totalBalanceXAxis.CostRecovery = this.reportTemplate.TotalBalanceXAxisCostRecovery;
            this.costsJournalXAxis.Dropdown = this.reportTemplate.CostsJournalXAxisDropdown;
            this.costsJournalXAxis.WasteAmountKg = this.reportTemplate.CostsJournalXAxisWasteAmountKg;
            this.costsJournalXAxis.Costs = this.reportTemplate.CostsJournalXAxisCosts;
            this.costsJournalXAxis.CostsMwSt = this.reportTemplate.CostsJournalXAxisCostsMwSt;
            this.costsJournalXAxis.CostsTotal = this.reportTemplate.CostsJournalXAxisCostsTotal;
            this.timeComparisonXAxis.Dropdown = this.reportTemplate.TimeComparisonXAxisDropdown;
            this.timeComparisonXAxis.PerMonthXAxis = this.reportTemplate.TimeComparisonXAxisPerMonthXAxis;
            this.timeComparisonXAxis.PerYearXAxis = this.reportTemplate.TimeComparisonXAxisPerYearXAxis;
            this.timeComparisonXAxis.TotalXAxis = this.reportTemplate.TimeComparisonXAxisTotalXAxis;

        },
        closePopup() {
            this.showDownloadPopup = false;
        },

    },
};
