<template>
  <div id="app">
    <Loader v-if="isLoading"></Loader>
    <router-view
        v-else
        v-on:open_update_bill="updateBill"
        v-on:open_update_contact="updateContact"
        v-on:open_update_organisation="updateOrganisation"
        v-on:open_update_department="updateDepartment"
        v-on:open_delete_popup="deletePopupBox"
        v-on:open_message_box="messageBox"
    />
    <Navigation v-if="getIsLoggedIn()"></Navigation>
    <UpdatePopup
        v-if="billToUpdate && updatePopup"
        v-on:close_update_popup="toggleUpdatePopup"
        :bill="billToUpdate[0]"
    ></UpdatePopup>
    <UpdatePopup
        v-if="contactToUpdate && updatePopup"
        v-on:close_update_popup="toggleUpdatePopup"
        :contact="contactToUpdate[0]"
    ></UpdatePopup>
    <UpdatePopup
        v-if="organisationToUpdate && updatePopup"
        v-on:close_update_popup="toggleUpdatePopup"
        :organisation="organisationToUpdate[0]"
    ></UpdatePopup>
    <UpdatePopup
        v-if="departmentToUpdate && updatePopup"
        v-on:close_update_popup="toggleUpdatePopup"
        :department="departmentToUpdate[0]"
    ></UpdatePopup>
    <DeletePopup
        v-if="deletePopup"
        v-on:close_delete_popup="toggleDeletePopup"
        :prop-delete-id="this.propDeleteId"
        :prop-type="this.propType">
    </DeletePopup>
    <MessageBox
        v-if="messageBoxShow"
        v-on:close_message_box="toggleMessageBox"
        :message-type="this.messageType"
    ></MessageBox>
  </div>
</template>

<style lang="scss">
@import "~@/styles/main.scss";
</style>
<script>
import {mapGetters, mapMutations} from "vuex";
import communesService from "@/services/communes/communes.service";
import billsService from "@/services/bills/bills.service";
import addressbookService from "@/services/addressbook/addressbook.service.js";

import Navigation from "@/components/Navigation/Navigation.vue";
import UpdatePopup from "@/components/UpdatePopup/UpdatePopup.vue";
import DeletePopup from "@/components/DeletePopup/DeletePopup.vue";
import Loader from "@/views/Loader/Loader.vue";
import Cookies from "js-cookie";
import MessageBox from "@/components/MessageBox/MessageBox.vue";


export default {
  components: {
    Navigation,
    Loader,
    UpdatePopup,
    DeletePopup,
    MessageBox
  },
  data() {
    return {
      isLoading: true,
      updatePopup: false,
      deletePopup: false,
      messageBoxShow: false,
      billToUpdate: null,
      contactToUpdate: null,
      organisationToUpdate: null,
      departmentToUpdate: null,
      propDeleteId: null,
      propType: null,
      messageType: '',
      allCommunes: [],
    };
  },
  computed: {
    initialCommune: function () {
      return this.allCommunes[0];
    },
    selector: function() {
      const selector = {
        deleteButton: document.querySelector('.btn__danger--active')
      }
      return selector
    }
  },

  async created() {
    this.setInitialEnviroment();
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },

  methods: {
    ...mapGetters(["getEnviroment", "getIsLoggedIn"]),
    ...mapMutations(["SET_COMMUNITY", "SET_YEAR"]),

    async setInitialEnviroment() {
      var activeYear = Cookies.get("enviromentYear");
      var activeCommune = Cookies.get("enviromentCommune");

      if (activeYear == null) {
        this.SET_YEAR(new Date().getFullYear());
      } else {
        this.SET_YEAR(activeYear);
      }
      if (activeCommune == null) {
        this.allCommunes = await communesService.getComunes();
        this.SET_COMMUNITY(this.initialCommune);
      } else {
        this.SET_COMMUNITY(JSON.parse(activeCommune));
      }
    },
    toggleUpdatePopup() {
      this.updatePopup = !this.updatePopup;
      if (!this.updatePopup) {
        this.billToUpdate = null;
        this.contactToUpdate = null;
        this.organisationToUpdate = null;
        this.departmentToUpdate = null;
      }
    },
    toggleDeletePopup(id,type) {
      this.deletePopup = !this.deletePopup;
      if(this.deletePopup){
        this.propDeleteId = id;
        this.propType = type;
      }
    },
    toggleMessageBox(messageType){
      this.messageBoxShow = !this.messageBoxShow;
      if(this.messageBoxShow){
        this.messageType = messageType;
      }
    },
    async updateBill(billId) {
      this.billToUpdate = await billsService.getBill(billId);
      this.toggleUpdatePopup();
    },
    deletePopupBox(id,type) {
      this.toggleDeletePopup(id,type);
    },
    messageBox(messageType) {
      this.toggleMessageBox(messageType);
    },
    async updateContact(contactId) {
      this.contactToUpdate = await addressbookService.getContact(contactId);
      this.toggleUpdatePopup();
    },
    async updateOrganisation(organisationId) {
      this.organisationToUpdate = await addressbookService.getOrganisation(organisationId);
      this.toggleUpdatePopup();
    },
    async updateDepartment(departmentId) {
      this.departmentToUpdate = await addressbookService.getDepartment(departmentId);
      this.toggleUpdatePopup();
    }
  },
};
</script>
