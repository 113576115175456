import Chart from 'chart.js'
import reportsService from "@/services/reports/reports.service";

export default {
    name: 'PieChart',
    props: {
        header: Array,
        filter: Object,
        allData: Boolean,
        totalBalanceXAxis: Object
    },
    data() {
        return {
            showChart:{
                waste: false,
                costs: false,
                revenues: false
            },
            statisticData: {
                AccountNr: 0,
                AccountName: 0,
                WasteAmountKg: 0,
                WasteAmountPercent: 0,
                CostsFr: 0,
                CostsPercent: 0,
                CostsFrT: 0,
                RevenuesFr: 0,
                RevenuesPercent: 0,
                RevenuesFrT: 0,
                CostRecoveryFr: 0
            },
            graph: {
                waste: {},
                costs: {},
                revenues: {}
            },
            WasteAmountDataPlaceholer: {
                label: [],
                data: [],
                backgroundColor: [],
                hoverOffset: 4
            },
            CostsDataPlaceholder: {
                label: [],
                data: [],
                backgroundColor: [],
                hoverOffset: 4
            },
            RevenuesDataPlaceholder: {
                label: [],
                data: [],
                backgroundColor: [],
                hoverOffset: 4
            },
            PieChart: {
                WasteAmount: {
                    type: "pie",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true
                    }
                },
                Costs: {
                    type: "pie",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true
                    }
                },
                Revenues: {
                    type: "pie",
                    data: {
                        labels: [],
                        datasets: []
                    },
                    options: {
                        responsive: true
                    }
                }
            }
        }
    },
    async created() {
        this.statisticData = await reportsService.getReportTotalBalance(this.filter);
        this.createChart();
    },
    mounted() {
        this.$root.$on('update_statistics',async () => {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
            }
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.accounts': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.startDate': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'filter.endDate': async function () {
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.WasteAmount': async function (value) {
          this.showChart.waste = !value;
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.Costs': async function (value) {
            this.showChart.costs = !value;
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
        'totalBalanceXAxis.Revenues': async function (value) {
            this.showChart.revenues = !value;
            this.statisticData = await reportsService.getReportTotalBalance(this.filter);
            this.updateChart();
        },
    },
    methods: {
        randomRgba() {
            let o = Math.round, r = Math.random, s = 255;
            return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
        },
        getData() {
            this.PieChart.WasteAmount.data.labels = [];
            this.PieChart.Costs.data.labels = [];
            this.PieChart.Revenues.data.labels = [];
            this.PieChart.WasteAmount.data.datasets = [];
            this.PieChart.Costs.data.datasets = [];
            this.PieChart.Revenues.data.datasets = [];
            this.WasteAmountDataPlaceholer.data = [];
            this.CostsDataPlaceholder.data = [];
            this.RevenuesDataPlaceholder.data = [];

            for (let i = 0; i < this.statisticData.length; i++) {
                const randomColor = this.randomRgba();

                this.PieChart.WasteAmount.data.labels.push(this.statisticData[i].AccountName);
                this.PieChart.Costs.data.labels.push(this.statisticData[i].AccountName);
                this.PieChart.Revenues.data.labels.push(this.statisticData[i].AccountName);

                this.WasteAmountDataPlaceholer.backgroundColor.push(randomColor);
                this.CostsDataPlaceholder.backgroundColor.push(randomColor);
                this.RevenuesDataPlaceholder.backgroundColor.push(randomColor);

                this.WasteAmountDataPlaceholer.data.push(this.statisticData[i].WasteAmountKg);
                this.CostsDataPlaceholder.data.push(this.statisticData[i].CostsFr);
                this.RevenuesDataPlaceholder.data.push(this.statisticData[i].RevenuesFr);
            }

                this.PieChart.WasteAmount.data.datasets.push(this.WasteAmountDataPlaceholer);
                this.PieChart.Costs.data.datasets.push(this.CostsDataPlaceholder);
                this.PieChart.Revenues.data.datasets.push(this.RevenuesDataPlaceholder);

        },
        createChart() {
            this.getData();
            const ctx1 = document.querySelector('.pie-chart-waste-amount');
            const ctx2 = document.querySelector('.pie-chart-costs');
            const ctx3 = document.querySelector('.pie-chart-revenues');
            this.graph.waste = new Chart(ctx1, this.PieChart.WasteAmount);
            this.graph.costs = new Chart(ctx2, this.PieChart.Costs);
            this.graph.revenues = new Chart(ctx3, this.PieChart.Revenues);
        },
        updateChart() {
            this.getData();
            this.graph.waste.update(this.PieChart.WasteAmount);
            this.graph.costs.update(this.PieChart.Costs);
            this.graph.revenues.update(this.PieChart.Revenues);
        }
    }
}