// @ is an alias to /src
import billsService from "@/services/bills/bills.service";
import expensesService from "@/services/expenses/expenses.service";
import returnsService from "@/services/returns/returns.service";

import BillElement from "@/components/BillElement/BillElement.vue";
import FilterOverview from "@/components/FilterOverview/FilterOverview.vue";

export default {
  name: "Overview",
  components: {
    FilterOverview,
    BillElement,
  },
  data() {
    return {
      bills: [],
      searchValue: "",
      filter: "",
      limit: 20,
      currentFilter: "BILLS",
      showDownloadPopup: false,
      downloadUrl: null,
    };
  },
  async created() {
    if (this.$route.query.search) {
      this.searchValue = this.$route.query.search;
    }
    await this.getBills();
  },
  mounted() {
    this.$root.$on('close_create_popup',() => {
      this.$root.$emit('close_create_popup_closest');
    });
    this.$root.$on('refresh_entry',  () => {
      this.$emit("open_message_box", 'success');
    });
    this.$root.$on('delete_entry',  () => {
      this.$emit("open_message_box", 'danger');
    });
    this.$root.$on('update_overview', async () => {
      await this.getBills();
    });
    this.$root.$on('copy_entry_bill',  async () => {
      await this.getBills();
      this.$emit("open_message_box", 'info');
    });
    this.$root.$on('delete_entry_bill', async (id) => {
      await billsService.deleteBill(id);
      await this.getBills();
      this.$emit("open_message_box", 'danger');
    });
  },
  watch: {
    currentFilter: function () {
      this.limit = 20;
      this.getBills();
    },
  },
  computed: {
    total: function () {
      let sum = 0;
      for (let i = 0; i < this.bills.length; i++) {
        const bill = this.bills[i];
        if (bill.InvoiceType === 2) {
          sum = sum + bill.Revenues + bill.MwstRevenue;
        } else {
          sum = sum - bill.Costs - bill.MwstCosts;
        }
      }
      return sum;
    },
  },
  methods: {
    changeFilter(filter) {
      this.currentFilter = filter;
    },

    searchInBills(searchValue) {
      this.searchValue = searchValue;
      this.getBills();
    },

    async getBills() {
      if (this.currentFilter == "BILLS") {
        this.bills = await billsService.getBills(
          this.limit,
          this.searchValue,
          this.filter
        );
      } else if (this.currentFilter == "RETURNS") {
        this.bills = await returnsService.getReturns(
          this.limit,
          this.searchValue,
          this.filter
        );
      } else {
        this.bills = await expensesService.getExpenses(
          this.limit,
          this.searchValue,
          this.filter
        );
      }
    },

    async exportExcel() {
      if (this.currentFilter == "BILLS") {
        this.downloadUrl = await billsService.getBills(
          this.limit,
          this.searchValue,
          this.filter,
          true
        );
      } else if (this.currentFilter == "RETURNS") {
        this.downloadUrl = await returnsService.getReturns(
          this.limit,
          this.searchValue,
          this.filter,
          true
        );
      } else {
        this.downloadUrl = await expensesService.getExpenses(
          this.limit,
          this.searchValue,
          this.filter,
          true
        );
      }
      this.showDownloadPopup = true;
    },

    closeDownloadPopup() {
      this.showDownloadPopup = false;
      this.downloadUrl = null;
    },

    async deleteBill(id) {
      this.$emit("open_delete_popup", id,'bill');
    },

    updateBill(id) {
      this.$emit("open_update_bill", id);
    },

    showMore() {
      this.limit = this.limit + 10;
      this.getBills();
    },
  },
};
