import httpService from "../http.service";
import authorizationService from "../authorization.service";

const usersService = {
    async getUser(id) {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        await httpService
            .get(`/user/${id}`, authConfig)
            .then((response) => {
                res = response.data;
            })
            .catch((error) => {
                console.log(error);
                res = null;
            });
        return res;
    },
    async updateUser(user) {
        let res;
        const authConfig = await authorizationService.getNewHeader();
        const params = new URLSearchParams();
        params.append("Password", user.password);
        console.log(user.password);
        console.log(user.id);
        await httpService
            .put("/user/" + user.id, params, authConfig)
            .then((response) => {
                res = response.data;
            })
            .catch((error) => console.log(error));
        return res;
    },
};
export default usersService;
