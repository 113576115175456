import CreatePopup from "@/components/CreatePopup/CreatePopup.vue";

export default {
  name: "FilterOverview",
  components: {
    CreatePopup,
  },
  data() {
    return {
      returnsAreShown: true,
      expensesAreShown: true,
      searchValue: null,
      createPopupIsOpen: false,
    };
  },
  computed: {
    ctateState: function () {
      return this.returnsAreShown ? "return" : "expens";
    },
    allAreShown: function () {
      return this.returnsAreShown && this.expensesAreShown;
    },
  },
  created() {
    if (this.$route.query.search) {
      this.searchValue = this.$route.query.search;
    }
  },
  mounted () {
    this.$root.$on('close_create_popup',() => {
      this.toggleCreatePopup();
    });
    this.$root.$on('close_create_popup_closest',() => {
      this.createPopupIsOpen = false;
    });
  },
  watch: {
    searchValue: function () {
      if (this.searchValue.length > 2) {
        if (this.searchValue.includes(".") && this.searchValue.length < 4) {
          this.$emit("search_in_bills", "");
        } else {
          this.$emit("search_in_bills", this.searchValue);
        }
      } else {
        this.$emit("search_in_bills", "");
      }
    },
  },
  methods: {
    exportExcel() {
      this.$emit("export_excel", "");
    },

    showReturns() {
      if (!this.expensesAreShown) {
        return;
      }
      this.expensesAreShown = false;
      this.returnsAreShown = true;
      this.changeFilterState();
    },
    showExpenses() {
      if (!this.returnsAreShown) {
        return;
      }
      this.expensesAreShown = true;
      this.returnsAreShown = false;
      this.changeFilterState();
    },
    showAll() {
      if (this.allAreShown) {
        return;
      }
      this.expensesAreShown = true;
      this.returnsAreShown = true;
      this.changeFilterState();
    },
    changeFilterState() {
      if (this.returnsAreShown && this.expensesAreShown) {
        this.$emit("change_filter", "BILLS");
      } else if (this.returnsAreShown) {
        this.$emit("change_filter", "RETURNS");
      } else if (this.expensesAreShown) {
        this.$emit("change_filter", "EXPENSES");
      } else {
        return;
      }
    },
    toggleCreatePopup() {
      this.createPopupIsOpen = !this.createPopupIsOpen;
    },
  },
};
