import {mapGetters} from "vuex";
import addressbookService from "@/services/addressbook/addressbook.service";
import billsService from "@/services/bills/bills.service";
import accountsService from "@/services/accounts/accounts.service";
import yearsService from "@/services/years/years.service";

export default {
    name: "CreateBill",
    components: {},
    props: {
        type: Number,
        billToUpdate: Object,
    },
    data() {
        return {
            bill: {
                CommunityId: null,
                OrganisationId: null,
                DepartmentId: null,
                AccountId: null,
                Year: null,
                InvoiceDate: null,
                DueDate: '01.01.1999',
                InvoiceReference: null,
                InvoiceType: null,
                PieceNumber: 0,
                CubicMetres: 0,
                Weight: 0,
                Costs: 0,
                MwstCosts: 0,
                Revenues: 0,
                MwstRevenue: 0,
            },
            step: 1,
            mwstPrecentage: '',
            changeMwst: false,
            organisations: [],
            accounts: [],
            departments: [],
            isComplete: true,
            currentYear: [{
                MwSt: 0
            }]
        };
    },
    computed: {
        calculatedMwst: function () {
            let amount = this.type == 1 ? this.bill.Costs : this.bill.Revenues;
            let calculated = 0;
            if (!amount && !this.bill.Costs && !this.bill.Revenues)
                calculated = (amount / 100) * this.mwstPrecentage;
            if (!amount && this.bill.Costs && !this.bill.Revenues)
                calculated = (this.bill.Costs / 100) * this.mwstPrecentage;
            if (amount && this.bill.Costs && !this.bill.Revenues)
                calculated = (this.bill.Costs / 100) * this.mwstPrecentage;
            if (!amount && !this.bill.Costs && this.bill.Revenues)
                calculated = (this.bill.Revenues / 100) * this.mwstPrecentage;
            if (amount && !this.bill.Costs && this.bill.Revenues)
                calculated = (this.bill.Revenues / 100) * this.mwstPrecentage;
            return calculated
        },
        cantForward: function () {
            return (
                !this.bill.InvoiceReference ||
                !this.bill.InvoiceDate ||
                !(this.bill.Costs || this.bill.Revenues)
            );
        },
        cantSubmit: function () {
            return !this.bill.OrganisationId || !this.bill.AccountId;
        },
    },
    async created() {
        this.bill.InvoiceType = this.type;
        this.bill.Year = this.getYear();
        this.bill.CommunityId = this.getCommunity().CommunityId;
        this.organisations = await addressbookService.getOrganisations();
        this.accounts = await accountsService.getAccounts();
        if (this.billToUpdate) {
            this.bill = this.billToUpdate;
            this.bill.InvoiceType = this.billToUpdate.InvoiceType;
            this.bill.InvoiceDate = this.bill.InvoiceDate.date.split(" ")[0];
            this.departments = await addressbookService.getDepartmentsByOrganisationId(this.billToUpdate.OrganisationId);
        }
        this.currentYear = await yearsService.getYearByYear(this.getYear());
        this.mwstPrecentage = this.currentYear[0].MwSt;
    },
    watch: {
        type: function () {
            this.step = 1;
            this.clearForm();
        },
    },
    methods: {
        ...mapGetters(["getYear", "getCommunity"]),
        async onChangeOrganisation(e) {
            this.departments = await addressbookService.getDepartmentsByOrganisationId(e.target.value);
        },
        clearForm() {
            this.bill.OrganisationId = null;
            this.bill.DepartmentId = null;
            this.bill.AccountId = null;
            this.bill.InvoiceDate = null;
            this.bill.DueDate = null;
            this.bill.InvoiceReference = null;
            this.bill.InvoiceType = this.type;
            this.bill.PieceNumber = null;
            this.bill.CubicMetres = null;
            this.bill.Weight = null;
            this.bill.Costs = null;
            this.bill.MwstCosts = 0;
            this.bill.Revenues = null;
            this.bill.MwstRevenue = 0;
        },
        forward() {
            if (this.cantForward) {
                return (this.isComplete = false);
            }
            this.isComplete = true;
            this.step = 2;
        },
        async submit() {
            if (this.cantSubmit) {
                return (this.isComplete = false);
            }
            this.isComplete = true;

            this.type == 1
                ? (this.bill.MwstCosts = this.calculatedMwst)
                : (this.bill.MwstRevenue = this.calculatedMwst);
            await billsService.createBill(this.bill);
            this.clearForm();
            this.step = 1;
            this.$root.$emit('update_overview');
            this.$root.$emit('refresh_entry');
            this.$root.$emit("close_create_popup");
        },
        async organisationHasDepartment($organisationId) {
            let departments = await addressbookService.getDepartmentsByOrganisationId($organisationId);
            return departments.length === 0;
        },
        async update() {
            this.bill.Costs
                ? (this.bill.MwstCosts = this.calculatedMwst)
                : (this.bill.MwstRevenue = this.calculatedMwst);
            if (await this.organisationHasDepartment(this.bill.OrganisationId))
                this.bill.DepartmentId = null;

            await billsService.updateBill(this.bill);
            this.$root.$emit('update_overview');
            this.$emit("close_update_popup", true);
            this.$root.$emit('refresh_entry');
        },
    },
};
