import Chart from 'chart.js'
import reportsService from "@/services/reports/reports.service";

export default {
    name: 'LineChart',
    props: {
        header: Array,
        filter: Object,
        allData: Boolean,
        timeComparisonXAxis: Object,
    },
    data() {
        return {
            statisticData: [],
            headers: [],
            showChart: {
                amount: true,
                costs: false
            },
            graph: {},
            LineChart: {
                type: "line",
                data: {
                    labels: [],
                    datasets: []
                },
                options: {
                    scales: {
                        yAxes: [{
                            stacked: true
                        }]
                    }
                }

            }
        }
    },
    async created() {
        await this.createChart();
    },
    mounted() {
        this.$root.$on('update_statistics', async () => {
            this.updateChart();
        });
    },
    watch: {
        allData: async function (value) {
            if (value) {
                this.filter.startDate = '';
                this.filter.endDate = '';
            }
            this.updateChart();
        },
        'timeComparisonXAxis.Dropdown': async function () {
            this.updateChart();
        },
        'timeComparisonXAxis.TotalXAxis': async function () {
            this.updateChart();
        },
        'timeComparisonXAxis.PerMonthXAxis': async function (value) {
            if (value) {
                this.timeComparisonXAxis.PerYearXAxis = false;
                this.updateChart();
            }
        },
        'timeComparisonXAxis.PerYearXAxis': async function (value) {
            if (value) {
                this.timeComparisonXAxis.PerMonthXAxis = false;
                this.updateChart();
            }
        },
        'filter.endDate': async function () {
            this.updateChart();

        },
        'filter.accounts': async function () {
            this.updateChart();
        },
    },
    methods: {
        randomRgba() {
            let o = Math.round, r = Math.random, s = 255;
            return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
        },
        emptyData() {
            this.headers.length = 0;
            this.statisticData.length = 0;
            this.monthNumbers.length = 0;
            this.yearsNumbers.length = 0;
            this.LineChart.data.labels.length = 0;
            this.LineChart.data.datasets.length = 0;
        },
        getHeaders: function (data) {
            for (let i = 1; i < data[0].length; i++) {
                this.LineChart.data.labels.push(data[0][i]);
            }
        },
        getStatisticData: function (data) {
            for (let i = 1; i < data.length; i++) {
                this.statisticData.push(data[i]);
            }
        },
        async getTimeComparisonDataStatistic(data) {
            this.emptyData();
            this.getHeaders(data);
            this.getStatisticData(data);
        },
        async getData() {
            const data = await reportsService.getReportTimeComparison(this.filter, this.timeComparisonXAxis);
            await this.getTimeComparisonDataStatistic(data);
            this.LineChart.data.datasets = [];
            for (let i = 0; i < this.statisticData.length; i++) {
                const randomColor = this.randomRgba();
                const timeComparisonDataObject = {
                    label: this.statisticData[i][0],
                    type: "line",
                    data: [],
                    borderColor: randomColor,
                    borderWidth: 3,
                    fill: false,
                    spanGaps: true
                };
                for (let x = 1; x < this.statisticData[i].length; x++) { //4 soviel wie es accounts hat
                    timeComparisonDataObject.data.push(this.statisticData[i][x].replace('kg','').replace(' Fr.', ''));
                }
                this.LineChart.data.datasets.push(timeComparisonDataObject);
            }
        },
        async createChart() {
            await this.getData();
            const ctx1 = document.querySelector('.line-chart');
            this.graph = new Chart(ctx1, this.LineChart);
        },
        async updateChart() {
            this.emptyData();
            await this.getData();
            this.graph.update(this.LineChart);
        }
    }
}