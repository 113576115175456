// @ is an alias to /src

export default {
  name: "Forecast",
  components: {},
  data() {
    return {
      dataFromAPI: {},
    };
  },
  async created() {},
};
