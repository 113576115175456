// @ is an alias to /src
import moment from "moment";
import billsService from "@/services/bills/bills.service";

export default {
  name: "BillElement",
  props: {
    bill: Object,
  },
  data() {
    return {
      date: "",
      isHidden: false,
      isActive: false,
    };
  },
  async created() {
    const unformatedDate = new Date(this.bill.InvoiceDate.date);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    this.date = unformatedDate.toLocaleDateString(undefined, options);
  },
  methods: {
    getDateFormat: function (date) {
      // `this` inside methods point to the Vue instance
      return moment(String(date)).format("MM.DD.YYYY");
    },
    deleteBill(id) {
      this.$emit("delete_bill", id);
    },
    updateBill(id) {
      this.$emit("update_bill", id);
    },
    async copyBill(id){
      await billsService.copyBill(id);
      this.$root.$emit("copy_entry_bill");
    }
  },
};
