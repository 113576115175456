import BarChartTotalBalance from "./types/TotalBalance/BarChartTotalBalance/BarChartTotalBalance.vue";
import PieChartTotalBalance from "./types/TotalBalance/PieChartTotalBalance/PieChartTotalBalance.vue";
import LineChartTotalBalance from "./types/TotalBalance/LineChartTotalBalance/LineChartTotalBalance.vue";
import BarChartJournal from "./types/Journal/BarChartJournal/BarChartJournal.vue";
import PieChartJournal from "./types/Journal/PieChartJournal/PieChartJournal.vue";
import LineChartJournal from "./types/Journal/LineChartJournal/LineChartJournal.vue";
import BarChartTimeComparison from "./types/TimeComparison/BarChartTimeComparison/BarChartTimeComparison.vue";
import LineChartTimeComparison from "./types/TimeComparison/LineChartTimeComparison/LineChartTimeComparison.vue";

export default {
  name: "Chart",
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    filter: Object,
    allData: Boolean,
    chartData: Object,
    totalBalanceXAxis:Object,
    costsJournalXAxis:Object,
    timeComparisonXAxis:Object,
    statisticData: Array,
    type: {
      type: String,
      default: null,
    },
    statisticTypes:Object
  },
  components: {
    BarChartTotalBalance,
    PieChartTotalBalance,
    LineChartTotalBalance,
    BarChartJournal,
    PieChartJournal,
    LineChartJournal,
    BarChartTimeComparison,
    LineChartTimeComparison
  },
  data() {
    return {
      chartLabels: [],
      newData: [],
      chosenChart: "bar",
      lineData: {
        labels: ["week 1", "week 2", "week 3", "week 4", "week 5", "week 6"],
        datasets: [
          {
            data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
            label: "Default",
            borderColor: "#A2C309",
            backgroundColor: "#A2C309",
            fill: false,
          },
        ],
      },
      pieData: {
        labels: ["week 1", "week 2"],
        datasets: [
          {
            data: [100],
            label: "Default",
            backgroundColor: ["#A2C309", "#1AA2C3", "#C22D1D", "#C22888"],
            fill: false,
          },
        ],
      },
      barData: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      },
      pieOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Chart.js Pie Chart",
          },
        },
      },

      barOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Chart.js Bar Chart",
          },
        },
      },
      lineOptions: {
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          titleFontSize: 20,
          bodyFontSize: 25,
        },
        scales: {
          xAxes: [],
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    statisticData: {
      handler: function () {
        this.updateChart();
      },
      deep: true,
    },
    chartData: {
      handler: function () {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    updateChart: function () {
      if (this.chartData.Account) {
        this.getAccounts();
        if (this.chartData.Total) {
          this.getTotalData();
        }
      }
    },
    getAccounts: function () {
      this.chartLabels = [];
      this.statisticData.forEach((element) => {
        if (!element) return;
        var label = element["Name"];
        if (
          this.chartData.PerMonth ||
          (!this.chartData.PerMonth && !this.chartLabels.includes(label))
        ) {
          this.chartLabels.push(label);
        }

        this.pieData.labels = this.chartLabels;
        this.lineData.labels = this.chartLabels;
      });
    },
    getTotalData: function () {
      this.newData = [];
      if (this.chartData.PerMonth) {
        this.statisticData.forEach((element) =>
          this.newData.push(element["Erlöse CHF"])
        );
      } else {
        this.chartLabels.forEach((label) => {
          //summ up total for every label if not per Month
          this.newData.push(
            Object.entries(this.statisticData)
              .filter((el) => el[1] && el[1].Name === label)
              .reduce((acc, curr) => acc + curr[1]["Erlöse CHF"], 0)
          );
        });
      }
      this.pieData.datasets[0].label = "Total";
      this.pieData.datasets[0].data = this.newData;
      this.lineData.datasets[0].label = "Total";
      this.lineData.datasets[0].data = this.newData;
    },
  },
};
