import Vue from "vue";
import VueRouter from "vue-router";
import Overview from "../views/Overview/Overview.vue";
import Contacts from "../views/Contacts/Contacts.vue";
import Statistic from "../views/Statistic/Statistic.vue";
import Forecast from "../views/Forecast/Forecast.vue";
import Profile from "../views/Profile/Profile.vue";
import Login from "../views/Login/Login.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Overview",
    component: Overview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/statistics",
    name: "Statistic",
    component: Statistic,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forecast",
    name: "Forecast",
    component: Forecast,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts",
    name: "Adressbook",
    component: Contacts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profil",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getIsLoggedIn) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
